import { Avatar, Box, Flex, HStack, Image, ListItem, Tag, TagLabel, Text, UnorderedList, useBreakpointValue, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { Fragment, useMemo, useState } from 'react'
import { BsFillEyeFill, BsFillLightningChargeFill } from 'react-icons/bs'
import { RiFireFill } from 'react-icons/ri'
import FormatPortalLinks from '~/utils/Functions/FormatPortalLinks'
import { GetColorTags } from '~/utils/GetColorTags'
import { PostHomeProps } from '~/utils/Types/Home'
import { ButtonIcon } from '../Buttons/ButtonIcon'
import { PopoverShare } from '../PopoverShare'
import { Title } from '../Title'
import { CardHeightNews } from './CardHighNews'
// import ImageNext from 'next/image'

type MainHeightNewsProps = {
	breakingNews: PostHomeProps[]
}

export default function MainHeightNews({ breakingNews }: MainHeightNewsProps) {
	const [page, setPage] = useState(1)
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	})

	const postsPagined = useMemo(() => {
		const start = page === 1 ? 0 : page === 2 ? 4 : page === 3 ? 8 : 12
		const end = page === 1 ? 4 : page === 2 ? 8 : page === 3 ? 12 : 16
		return breakingNews.slice(start, end)
	}, [breakingNews, page])


	return (
		<Flex width="100%" maxWidth="945px" flexDirection="column">
			<Flex justify="space-between" width="100%">
				<Title text="Leia Mais" as='h3' />
				{isWideVersion &&
					<Flex gap="3">
						<ButtonIcon linkHref='/' background="#FBEAE3" color="#E2221E" icon={RiFireFill} />
						<ButtonIcon linkHref='/' background="#E5D9FF" color="#000" icon={BsFillLightningChargeFill} />
						<ButtonIcon linkHref='/' background="#DFF2FF" color="#000" icon={BsFillEyeFill} />
					</Flex>
				}
			</Flex>
			{postsPagined.map((post, index) => (
				<Fragment key={post.secure_id}>
					{(index === 0 && isWideVersion) ? (
						<Flex
							marginTop="6"
							position="relative"
							align="flex-end"
						>
							{!!post.is_sponsored && (
								<Tag
									size="md"
									borderRadius='full'
									variant='solid'
									position="absolute"
									background="gray"
									right="20px"
									top="10px"
								>
									<TagLabel>Patrocinado</TagLabel>
								</Tag>
							)}
							<Box
								as={Link}
								href={FormatPortalLinks.formatPostLink({ post })}
							>
								<Image
									height="600px"
									rounded="lg"
									width="100%"
									objectFit="cover"
									src={post.thumbnail?.url}
									alt={post.thumbnail?.alt_text ?? post.meta_title}
								/>
								{/* <ImageNext
									style={{
										borderRadius: 8,
										width: '100%',
										minHeight: '100%',
										height: '600px',
									}}
									width={100}
									height={600}
									quality={100}
									objectFit="cover"
									src={post.thumbnail?.url ?? ''}
									alt={post.thumbnail?.alt_text ?? post.meta_title ?? ''}
								/> */}
							</Box>
							<VStack
								position="absolute"
								padding="16"
								align="flex-start"
							>
								{!!post.tags && post.tags.length && (
									<HStack as={Link}
										href={FormatPortalLinks.formatPostLink({ post })}>
										{post.tags.split(',').slice(0, 6).map((tag, index) => (
											<Tag
												key={tag}
												size="lg"
												borderRadius='full'
												variant='solid'
												background={GetColorTags(index)}
											>
												<TagLabel>{tag}</TagLabel>
											</Tag>
										))}
									</HStack>
								)}
								<Box
									as={Link}
									href={FormatPortalLinks.formatPostLink({ post })}
								>

									<Text as='h4' textStyle="headerXL" color="whiteAlpha.900" fontSize="2.5rem">
										{post.title}
									</Text>
								</Box>

								<Flex
									marginTop="3"
									alignItems="center"
								>
									<HStack
										spacing={1}
										as={Link}
										href={FormatPortalLinks.formatPostLink({ post })}
									>
										<Avatar
											size="xs"
											name={post.is_produced ? post.produced : post?.author?.name}
											src={post.is_produced ? "" : post?.author?.avatar?.url}
											css={{
												'&>div': {
													marginTop: "2px"
												}
											}}
										/>
										<Text
											marginLeft="2"
											mb="-0.35rem"
											textStyle="textSM"
											textTransform="uppercase"
											color="whiteAlpha.900"
										>
											POR {post.is_produced ? post.produced : post.author?.name}
										</Text>
									</HStack>
									<UnorderedList display="flex" listStyleType="none">
										<ListItem
											marginLeft="-0.5"
											display="flex"
											alignItems="center"
										>
											<PopoverShare linkHref={FormatPortalLinks.formatPostLink({ post: post })} postId={post.secure_id} />
										</ListItem>
									</UnorderedList>

									{/* <Text
										mb="-0.35rem"
										textStyle="textSM"
										color="whiteAlpha.900"
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Icon
											as={TbCircleFilled}
											color='#FFF'
											fontSize="6px"
											marginLeft="3"
										/>
										<Icon
											marginLeft="3"
											as={HiOutlineArrowTrendingUp}
											color="green"
											marginRight="1"
											fontSize="lg"
										/>
										{post.view_count} views
									</Text> */}
								</Flex>
							</VStack>
						</Flex>
					) : (
						<CardHeightNews post={post} index={index} />
					)
					}
				</Fragment >
			))}
			{/* {isWideVersion && (
				<HStack marginTop="8">
					<Button
						background={page === 1 ? "#000" : undefined}
						color={page === 1 ? "#FFF" : undefined}
						rounded="full"
						onClick={() => setPage(1)}
					>
						1
					</Button>
					{breakingNews.length > 4 && (
						<Button
							background={page === 2 ? "#000" : undefined}
							color={page === 2 ? "#FFF" : undefined}
							rounded="full"
							onClick={() => setPage(2)}
						>
							2
						</Button>
					)}
					{breakingNews.length > 8 && (
						<Button
							background={page === 3 ? "#000" : undefined}
							color={page === 3 ? "#FFF" : undefined}
							rounded="full"
							onClick={() => setPage(3)}
						>
							3
						</Button>
					)}
					{breakingNews.length > 12 && (
						<Button
							background={page === 4 ? "#000" : undefined}
							color={page === 4 ? "#FFF" : undefined}
							rounded="full"
							onClick={() => setPage(4)}
						>
							4
						</Button>
					)}
				</HStack>
			)} */}
		</Flex>
	)
}
