import {
	Icon,
	IconButton,
	Image,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	SimpleGrid,
	useClipboard,
} from "@chakra-ui/react"
import { FC, ReactNode, useCallback, useMemo } from "react"
import { RiCheckLine, RiFacebookFill, RiFileCopyLine, RiLinkedinFill, RiShareFill, RiTelegramFill, RiTwitterFill, RiWhatsappFill } from "react-icons/ri"

import { api } from "~/services/apiClient"

import { LinkShare } from "./LinkShare"

interface PopoverShareProps {
	linkHref: string
	postId?: string
	type?: 'other' | 'icon'
	children?: ReactNode
	maxWidth?: number | string
}

export const PopoverShare: FC<PopoverShareProps> = ({ linkHref, postId, type = 'icon', children, maxWidth }) => {
	const linkToShare = useMemo(() => {
		return `${process.env.NEXT_PUBLIC_HOST_URL}${linkHref}`
	}, [])

	const { onCopy, hasCopied } = useClipboard(linkToShare)

	const handleGenerateSharing = useCallback(async () => {
		if (postId) {
			await api.put(`/v1/posts-metrics/${postId}`, {
				type: 'sharing'
			})
		}
	}, [postId])

	return (
		<Popover
			placement="auto"
			// closeOnBlur={false}
			closeOnEsc
		>
			<PopoverTrigger>
				{type === 'other' && children ? (
					children
				) : (
					<IconButton
						variant='ghost'
						colorScheme='teal'
						aria-label='Share'
						fontSize='xl'
						icon={<RiShareFill />}
					/>
				)}
			</PopoverTrigger>
			<PopoverContent borderColor="gray.400" shadow="md" maxW={maxWidth}>
				<PopoverHeader pt={4} fontWeight='bold' border='0'>
					Compartilhar
					<IconButton
						ml="2"
						variant='unstyled'
						colorScheme='teal'
						aria-label='Share'
						fontSize='lg'
						onClick={() => {
							onCopy()
							handleGenerateSharing()
						}}
						icon={hasCopied ? <RiCheckLine /> : <RiFileCopyLine />}
					/>
				</PopoverHeader>
				<PopoverArrow bg='blue.800' />
				<PopoverCloseButton />
				<PopoverBody>
					<SimpleGrid w="100%" gap="2" minChildWidth="70px">
						<LinkShare
							href={`https://twitter.com/intent/tweet?url=${linkToShare}`}
							icon={RiTwitterFill}
							color="twitter.400"
							onClick={handleGenerateSharing}
						>
							Twitter
						</LinkShare>
						<LinkShare
							href={`https://www.facebook.com/sharer/sharer.php?u=${linkToShare}`}
							icon={RiFacebookFill}
							color="facebook.400"
							onClick={handleGenerateSharing}
						>
							Facebook
						</LinkShare>
						<LinkShare
							href={`https://www.linkedin.com/shareArticle?mini=true&url=${linkToShare}`}
							icon={RiLinkedinFill}
							color="linkedin.400"
							onClick={handleGenerateSharing}
						>
							Linkedin
						</LinkShare>
						<LinkShare
							href={`https://api.whatsapp.com/send?text=${linkToShare}`}
							icon={RiWhatsappFill}
							color="whatsapp.400"
							onClick={handleGenerateSharing}
						>
							Whatsapp
						</LinkShare>
						<LinkShare
							href={`https://telegram.me/share/url?url=${linkToShare}`}
							icon={RiTelegramFill}
							color="telegram.400"
							onClick={handleGenerateSharing}
						>
							Telegram
						</LinkShare>
					</SimpleGrid>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
