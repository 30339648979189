import { Box, Button, ButtonProps, Icon } from "@chakra-ui/react"
import Link from "next/link"
import { FC } from "react"
import { IconType } from "react-icons"

interface ButtonReadMoreProps extends ButtonProps {
	linkHref: string
	icon: IconType
	sizeIcon?: string
	color?: string
}

export const ButtonIcon: FC<ButtonReadMoreProps> = ({ icon, linkHref, sizeIcon, color, bg, ...rest }) => {
	return (
		<Box display="inline-block">
			<Button
				as={Link}
				href={linkHref}
				passHref
				size="sm"
				fontSize="md"
				variant="solid"
				rounded="full"
				py={6}
				px={4}
				{...rest}
			>
				<Icon as={icon} fontSize={sizeIcon ?? "lg"} color={color ?? "#FFF"} />
			</Button>
		</Box>
	)
}
