import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { BsFillPlayCircleFill } from "react-icons/bs";

interface ButtonPlayProps extends ButtonProps {
	icon?: IconType
}

export function ButtonPlay({ icon, ...props }: ButtonPlayProps) {
	return (
		<Button
			position="absolute"
			bottom="17px"
			left="20px"
			padding="0"
			background="none"
			_hover={{
				background: "none"
			}}
			{...props}
		>
			{<Icon
				as={icon ?? BsFillPlayCircleFill}
				color="whiteAlpha.900"
				fontSize="xxx-large"
			/>}
		</Button>
	)
}
