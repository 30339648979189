import {
	Avatar,
	Button,
	Flex,
	GridItem,
	HStack,
	Icon,
	ListItem,
	Tag,
	TagLabel,
	Text,
	UnorderedList,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import Link from "next/link";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { PostHomeProps } from "~/utils/Types/Home";
import { PopoverShare } from "../PopoverShare";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";
import Image from "next/image";

interface CardTopicsProps {
	isFirstCard?: boolean;
	topicOfTheDay: PostHomeProps;
}

export function CardTopics({
	isFirstCard,
	topicOfTheDay,
}: CardTopicsProps) {
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	});
	const url = FormatPortalLinks.formatPostLink({ post: topicOfTheDay })

	const authorName = useMemo(() => {
		if (topicOfTheDay.is_produced) {
			return topicOfTheDay.produced;
		}

		return `${topicOfTheDay.author?.name.split(" ")[0]} ${topicOfTheDay.author?.name.split(" ")[1] ?? ""
			}`;
	}, [topicOfTheDay]);

	return (
		<GridItem
			rowSpan={isWideVersion ? (isFirstCard ? 3 : 1) : 2}
			colSpan={isWideVersion ? 2 : 1}
			display="flex"
			flexDirection={
				isWideVersion ? (isFirstCard ? "column" : "row") : "column"
			}
			gap="4"
		>
			<Flex
				as={Link}
				href={url}
				width={"100%"}
				maxW={isFirstCard || !isWideVersion ? undefined : "200px"}
				minW={isFirstCard || !isWideVersion ? undefined : "200px"}
				position="relative"
				height={isWideVersion ? (isFirstCard ? "420px" : "100%") : "250px"}
			>
				<Image
					style={{
						borderRadius: 10,
						objectFit: 'cover',
					}}
					layout="fill"
					priority
					src={topicOfTheDay.thumbnail?.url ?? ''}
					alt={topicOfTheDay.thumbnail?.alt_text ?? topicOfTheDay.meta_title ?? ''}
				/>
				{isFirstCard && !!topicOfTheDay.views && topicOfTheDay.views >= 500 && (
					<Button
						size="sm"
						fontSize="md"
						rounded="full"
						py={6}
						px={4}
						cursor="default"
						position="absolute"
						left="20px"
						top="20px"
						background="#7448DC"
						_hover={{
							background: "#7448DC",
						}}
					>
						<Icon
							as={BsFillLightningChargeFill}
							fontSize={"lg"}
							color={"#FFF"}
						/>
					</Button>
				)}
				<Flex position="absolute" top="14px" right="14px" gap="3">
					{!!topicOfTheDay.tags &&
						!topicOfTheDay.is_sponsored &&
						topicOfTheDay.tags.length &&
						topicOfTheDay.tags
							.split(",")
							.splice(0, isFirstCard ? 2 : 1)
							.map((item) => (
								<Tag
									key={item}
									zIndex="2"
									size="lg"
									padding="0 0,5rem"
									borderRadius="full"
									variant="solid"
									background="#0360D7"
									width="min-content"
								>
									<TagLabel mb="-0.15rem">{item}</TagLabel>
								</Tag>
							))}
					{!!topicOfTheDay.is_sponsored && (
						<Tag
							size="md"
							borderRadius="full"
							variant="solid"
							background="gray"
							right="20px"
							top="10px"
						>
							<TagLabel>Patrocinado</TagLabel>
						</Tag>
					)}
				</Flex>
			</Flex>
			<VStack spacing="0" alignItems="flex-start">
				<VStack align="flex-start">
					<Link href={url} passHref>
						<Text
							lineHeight="6"
							fontSize="x-large"
							color="#000"
							as="h4"
							fontWeight="black"
							noOfLines={3}
						>
							{topicOfTheDay.title}
						</Text>
					</Link>
				</VStack>
				<Flex flexDirection={isWideVersion ? "column" : "column-reverse"}>
					{!isFirstCard && (
						<Text color="#575757" noOfLines={1}>
							{topicOfTheDay.excerpt}
						</Text>
					)}
					{isFirstCard ? (
						<Flex marginTop="4">
							<Avatar
								size="sm"
								name={
									topicOfTheDay.is_produced
										? topicOfTheDay.produced
										: topicOfTheDay.author?.name
								}
								src={topicOfTheDay.is_produced ? "" : topicOfTheDay?.author?.avatar?.url}
								css={{
									"&>div": {
										marginTop: "2px",
									},
								}}
							/>
							<Text
								marginLeft="2"
								mb="-0.35rem"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								POR{" "}
								{topicOfTheDay?.is_produced
									? topicOfTheDay?.produced
									: topicOfTheDay?.author?.name}
							</Text>
							<UnorderedList display="flex" listStyleType="none">
								<ListItem marginLeft="-0.5" display="flex" alignItems="center">
									<PopoverShare
										linkHref={url}
										postId={topicOfTheDay.secure_id}
									/>
								</ListItem>
							</UnorderedList>
						</Flex>
					) : (
						<Flex marginTop="4">
							<Avatar
								css={{
									"&>div": {
										marginTop: "2px",
									},
								}}
								size="xs"
								name={authorName}
							/>
							<HStack>
								<Text
									color="#575757"
									marginLeft="2"
									display="flex"
									justifyContent="center"
									alignItems="center"
									lineHeight="1.2"
								>
									POR {authorName}
								</Text>
								<PopoverShare
									linkHref={url}
									postId={topicOfTheDay.secure_id}
								/>
							</HStack>
						</Flex>
					)}
				</Flex>
			</VStack>
		</GridItem>
	);
}
