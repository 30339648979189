import { AspectRatio, Box, Image, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { ButtonPlay } from "../Buttons/ButtonPlay";
import { VideosHomeProps } from "~/utils/Types/Home";
import { Dispatch, SetStateAction } from "react";
import { getInfosEmbed } from "~/utils/getInfosEmbed";

type CardVideoProps = {
	video: VideosHomeProps;
	setVideoSelectedSecureId: Dispatch<SetStateAction<string | undefined>>;
}

export function CardVideo({ video, setVideoSelectedSecureId }: CardVideoProps) {
	const isWideVersion = useBreakpointValue({
		base: false,
		"2xl": true,
	})

	return (
		<VStack width="100%" height="100%" align="flex-start" spacing="4">
			<Box position="relative" width="100%">
				{isWideVersion ? (
					<>
						<Image
							objectFit="cover"
							height="150px"
							width="100%"
							rounded="md"
							src={video.thumbnail.url}
							alt={video.thumbnail.alt_text ?? video.title}
							cursor="pointer"
							onClick={() => setVideoSelectedSecureId(video.secure_id)}
						/>
						<ButtonPlay />
					</>
				) : (
					<AspectRatio
						ratio={16 / 9}
						w="100%"
						css={{
							'iframe': {
								width: '100%',
								height: '100%'
							}
						}}
					>
						{video.embed.length ? (
							<iframe
								title={video.title}
								src={getInfosEmbed(video.embed).url}
							/>
						) : <></>}
					</AspectRatio>
				)}
			</Box>
			<VStack align="flex-start" spacing="1">
				<Text textStyle="headerMD" as="h3" color={isWideVersion ? undefined : "#fff"}>
					{video.title}
				</Text>
			</VStack>
		</VStack>
	)
}
