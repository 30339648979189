import { Flex, Image, ListItem, Text, useMediaQuery } from "@chakra-ui/react";
import Link from "next/link";
import { HotCategories } from "~/utils/Types/Home";

interface CardCategoryItemProps {
	hotCategoryItem: HotCategories;
}

export function CardHotCategoryItem({ hotCategoryItem }: CardCategoryItemProps) {
	const [isLargerThan990] = useMediaQuery('(min-width: 990px)')

	return (
		<ListItem
			href={`/temas/${hotCategoryItem.slug}`}
			as={Link}
			backgroundPosition="center"
			display="flex"
			alignItems="center"
			height="90px"
			rounded="lg"
			fill="red"
			position="relative"
		>
			<Image
				width="100%"
				height="100%"
				position="absolute"
				rounded="lg"
				objectFit="cover"
				src={hotCategoryItem.thumbnail?.url}
				alt={hotCategoryItem.thumbnail?.alt_text ?? hotCategoryItem.title}
			/>
			<Flex
				width="100%"
				height="100%"
				position="absolute"
				rounded="lg"
				background="rgba(0, 0, 0, 0.4)"
			>
			</Flex>
			<Text
				zIndex="2"
				paddingLeft={isLargerThan990 ? "15%" : "4"}
				color="whiteAlpha.900"
				fontWeight="black"
				textStyle="textLG"
				mb="-0.35rem"
			>
				{hotCategoryItem.title}
			</Text>
			<Flex
				align="center"
				justify="center"
				rounded="full"
				right="-20px"
				width="40px"
				height="40px"
				background="#FFF7F8"
				position="absolute"
			>
				<Text
					textStyle="textSM"
					mb="-0.35rem"
					fontWeight="black"
				>
					{hotCategoryItem.total_views}
				</Text>
			</Flex>
		</ListItem>
	)
}
