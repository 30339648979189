import {
	Avatar,
	Flex,
	GridItem,
	Image,
	Tag,
	TagLabel,
	Text,
	Stack,
	HStack,
	useMediaQuery,
} from "@chakra-ui/react";
import Link from "next/link";
import { PostHomeProps } from "~/utils/Types/Home";
import { PopoverShare } from "../PopoverShare";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";

interface CardAttackedItem {
	post: PostHomeProps;
}

export function CardAttackedItem({ post }: CardAttackedItem) {
	const [isSmallVersion] = useMediaQuery("(max-width: 340px)");

	return (
		<GridItem position="relative" width="100%">
			<Stack as={Link} href={FormatPortalLinks.formatPostLink({ post })} width="100%">
				{!!post.tags &&
					post.tags.length &&
					!post.is_sponsored &&
					post.tags
						.split(",")
						.splice(0, 1)
						.map((item) => (
							<Tag
								key={item}
								size="lg"
								borderRadius="full"
								variant="solid"
								background="#0360D7"
								position="absolute"
								top="20px"
								left="25px"
							>
								<TagLabel mb="-0.15rem">{item}</TagLabel>
							</Tag>
						))}
				{!!post.is_sponsored && (
					<Tag
						size="md"
						borderRadius="full"
						variant="solid"
						position="absolute"
						background="gray"
						right="25px"
						top="20px"
					>
						<TagLabel>Patrocinado</TagLabel>
					</Tag>
				)}
				<Image
					src={post.thumbnail?.url}
					alt={post.thumbnail?.alt_text ?? post.meta_description}
					height="250px"
					width="100%"
					objectFit="cover"
					rounded="lg"
				/>
				<Text
					marginTop="8"
					textStyle="headerXL"
					maxWidth="521px"
					fontSize="1.8rem"
					noOfLines={2}
					as={"h4"}
				>
					{post.title}
				</Text>
			</Stack>
			<Flex marginTop="3" alignItems="center">
				<Avatar
					size="xs"
					name={post.is_produced ? post.produced : post?.author?.name}
					src={post.is_produced ? "" : post?.author?.avatar?.url}
					css={{
						"&>div": {
							marginTop: "2px",
						},
					}}
				/>
				<Text
					marginLeft="2"
					mb="-0.10rem"
					textTransform="uppercase"
					textStyle="textSM"
				>
					POR {!!post.is_produced ? post.produced : post.author?.name}
				</Text>
				<PopoverShare
					linkHref={FormatPortalLinks.formatPostLink({ post })}
					postId={post.secure_id}
					maxWidth={isSmallVersion ? "220px" : "auto"}
				/>
			</Flex>
		</GridItem>
	);
}
