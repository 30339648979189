import {
	Box,
	Divider,
	Flex,
	Grid,
	Icon,
	Text,
	useMediaQuery,
	VStack,
} from "@chakra-ui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { NewsByCategoryProps } from "~/utils/Types/Home";
import { Title } from "../Title";
import { CardAttackedItem } from "./CardAttaccked";
import { CardAttackedFirstItem } from "./CardFirstItem";
import { Option } from "~/utils/Types/Global";

interface AttackedProps {
	newsByCategoryProps: NewsByCategoryProps[];
}

export function Attacked({ newsByCategoryProps }: AttackedProps) {
	const [isWideVersion] = useMediaQuery("(min-width: 1250px)");
	const [isUltraWideVersion] = useMediaQuery("(max-width: 1440px)");

	const [categorySecureId, setCategorySecureId] = useState<string | undefined>(
		newsByCategoryProps.length ? newsByCategoryProps[0].secure_id : undefined
	);
	const [showIntervalStartCategory, setShowIntervalStartCategory] = useState(0);
	const [showIntervalEndCategory, setShowIntervalEndCategory] = useState(0);

	const handleSelectCategory = (secureId: string) =>
		setCategorySecureId(secureId);

	const handleShowPrev = () => {
		if (showIntervalStartCategory > 0) {
			setShowIntervalStartCategory((oldValue) => oldValue - 1);
			setShowIntervalEndCategory((oldValue) => oldValue - 1);
		}
	};

	const handleShowNext = () => {
		if (showIntervalEndCategory < newsByCategoryProps.length - 1) {
			setShowIntervalStartCategory((oldValue) => oldValue + 1);
			setShowIntervalEndCategory((oldValue) => oldValue + 1);
		}
	};

	useEffect(() => {
		setShowIntervalEndCategory(isWideVersion ? 3 : 2);
	}, [isWideVersion]);

	const categoriesShow = useMemo(() => {
		if (!newsByCategoryProps) return []

		const originalArray = newsByCategoryProps.filter((_item, index) => index >= showIntervalStartCategory && index <= showIntervalEndCategory)
		const newArray: Option[] = []

		originalArray.forEach((obj, index) => {
			newArray.push({
				label: obj.title,
				value: obj.secure_id,
			});
			if (index !== originalArray.length - 1) {
				// Value tem que ser único se não buga infinito
				newArray.push({ label: '-', value: '-' + index });
			}
		});

		return newArray
	}, [newsByCategoryProps, showIntervalStartCategory, showIntervalEndCategory])

	return (
		<VStack
			as="section"
			background="#FFF"
			px="6"
		>
			<VStack
				maxWidth={!isUltraWideVersion ? "1326px" : "1440px"}
				width="100%"
				alignItems="flex-start"
				gap={7}
				marginTop={isWideVersion ? "44" : "130"}
				marginBottom={"10"}
				sx={{
					"@media (max-width: 1138px)": {
						marginTop: 10,
					},
					"@media (max-width: 370px)": {
						padding: 0,
					},
				}}
			>
				<Flex
					width="100%"
					justify="space-between"
					gap={isWideVersion ? undefined : "10"}
					align={isWideVersion ? "center" : "flex-start"}
					flexDir={isWideVersion ? "row" : "column-reverse"}
				>
					<Title text="Selecione seu Tema" as='h3' />
					<Grid
						rounded={{ sm: "none", lg: "3xl" }}
						borderColor="blackAlpha.400"
						borderWidth="2px"
						borderStyle="solid"
						gap={isWideVersion ? "1.5rem" : "0.8rem"}
						width="100%"
						gridTemplateColumns={'1fr 60px'}
					>
						<Grid
							width="100%"
							maxW="100%"
							gridTemplateColumns={{
								sm: "1fr 0.1fr 1fr 0.1fr 1fr",
								lg: "1fr 0.1fr 1fr 0.1fr 1fr 0.1fr 1fr 0.1fr 1fr"
							}}
							gap={{ sm: 1, lg: 5 }}
						>
							{categoriesShow.map((item, index) => (
								<Fragment key={item.value}>
									{categorySecureId === item.value ? (
										<Box
											background={{ sm: undefined, lg: "#DB0245" }}
											outline={{ sm: undefined, lg: "2px solid #DB0245" }}
											rounded={{ sm: "none", lg: "3xl" }}
											padding={{ sm: "0.5rem 0.2rem", lg: "0.4rem 1rem" }}
											textAlign="center"
										>
											<Text
												color="whiteAlpha.900"
												mb={{ sm: undefined, lg: "-0.35rem" }}
												whiteSpace="nowrap"
												fontSize={{ sm: "xx-small", md: "inherit" }}
												background={{ sm: "#DB0245", lg: 'transparent' }}
												padding={{ sm: 2, lg: 0 }}
											>
												{item.label}
											</Text>
										</Box>
									) : (
										<Text
											as="button"
											onClick={() => item.label === '-' ? () => { } : handleSelectCategory(item.value as string)}
											// mb="-0.35rem"
											whiteSpace="nowrap"
											color="blackAlpha.700"
											fontSize={{ sm: "xx-small", md: "inherit" }}
											padding={index === 0 ? "0 0 0 0.9rem" : undefined}
										>
											{item.label}
										</Text>
									)}
								</Fragment>
							))}
						</Grid>
						<Flex
							minW="100%"
							w="100%"
							height={{ sm: '46px', lg: "37px" }}
							justify="center"
							align="center"
							borderRadius="0 1rem 1rem 0"
							borderLeftColor="blackAlpha.400"
							borderLeftWidth="2px"
							padding="2"
						>
							<Flex as="button" marginRight="1" onClick={handleShowPrev}>
								<Icon as={BiLeftArrowAlt} fontSize="1xl" color="blackAlpha.700" />
							</Flex>
							<Divider orientation='vertical' border="1px solid blackAlpha.400" height="20px" />
							<Flex as="button" marginLeft="1" onClick={handleShowNext}>
								<Icon as={BiRightArrowAlt} fontSize="1xl" color="blackAlpha.700" />
							</Flex>
						</Flex>
					</Grid>
				</Flex>
				{newsByCategoryProps
					.filter((item) => item.secure_id === categorySecureId)
					.map((item) => (
						<Grid
							key={item.secure_id}
							width="100%"
							templateColumns={
								isWideVersion
									? "repeat(3, 1fr)"
									: "repeat(1, minmax(100%, 300px))"
							}
							justifyContent="center"
							templateRows="repeat(3, auto)"
							gap={7}
							position="relative"
						>
							{item.posts.map((postItem, index) => (
								<Fragment key={postItem.secure_id}>
									{(index === 0 && isWideVersion) ? (
										<CardAttackedFirstItem post={postItem} />
									) : (
										<CardAttackedItem post={postItem} />
									)}
								</Fragment>
							))}
						</Grid>
					))}
			</VStack>
		</VStack >
	);
}
