import {
	HStack,
	Text, useMediaQuery, VStack
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import FormatPortalLinks from '~/utils/Functions/FormatPortalLinks';
import { PostHomeProps } from '~/utils/Types/Home';

interface CardHighlightProps {
	highlight: PostHomeProps;
}

export const CardHighlight = ({ highlight }: CardHighlightProps) => {
	const [isLargerThan1020] = useMediaQuery('(min-width: 1020px)')
	const [thumbURL, setThumbURL] = useState(
		highlight.thumbnail?.url ?
			highlight.thumbnail.url.replace(
				highlight.thumbnail.url.split('/')[highlight.thumbnail.url.split('/').length - 1],
				`112x112_${highlight.thumbnail.url.split('/')[highlight.thumbnail.url.split('/').length - 1]}`
			)
			: ''
	)

	return (
		<HStack
			as={Link}
			href={FormatPortalLinks.formatPostLink({ post: highlight })}
			spacing={isLargerThan1020 ? 5 : 3}
		>
			<Image
				style={{
					borderRadius: '50%',
					minWidth: '112px',
					minHeight: '112px',
					maxWidth: '112px',
					maxHeight: '112px',
					objectFit: 'cover',
				}}
				width={112}
				height={112}
				src={thumbURL}
				onError={() => setThumbURL(highlight.thumbnail?.url ?? '')}
				alt={highlight.thumbnail?.alt_text ?? highlight.meta_title ?? ''}
			/>
			<VStack textAlign="start" spacing={2} w="100%">
				{highlight.categories && highlight.categories.length && (
					<Text
						textTransform="uppercase"
						fontSize="xs"
						fontWeight="bold"
						color="#4f0d27"
						w="100%"
						as="h3"
					>
						{highlight.categories[0].title}
					</Text>
				)}
				<Text
					fontSize="lg"
					fontWeight="bold"
					lineHeight="normal"
					w="100%"
					as="h4"
					noOfLines={3}
				>
					{highlight.title}
				</Text>
			</VStack>
		</HStack>
	)
}
