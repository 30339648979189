import {
	Flex,
	Image,
	ListIcon,
	ListItem,
	Text,
	UnorderedList,
	useBreakpointValue,
} from "@chakra-ui/react";
import Link from "next/link";
import { useLayoutEffect } from "react";
import { TbCircleFilled } from "react-icons/tb";
import { AdSlot } from "~/components/Ads/AdSlot";
import { defineSlot } from "~/lib/dfp";
import { NewsByCategoryProps, PostHomeProps, SocialMediaHomeProps } from "~/utils/Types/Home";
import { Title } from "../Title";
import { SlideEmphasisPost } from "./SlideEmphasisPost";

type SidebarHeightNewsProps = {
	featuredPost: PostHomeProps[]
	newsByCategory: NewsByCategoryProps[]
	socialMedias: SocialMediaHomeProps[]
};

export default function SidebarHeightNews({
	featuredPost,
	newsByCategory,
	socialMedias,
}: SidebarHeightNewsProps) {
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	});

	useLayoutEffect(() => {
		defineSlot('/100074611/Bloco-390x300', 'div-gpt-ad-1711110302742-0', [[390, 300], [312, 240]], ['Home Notícias em Alta 390x300'])
	}, [])

	return (
		<Flex
			width="100%"
			flexDir="column"
			align="center"
			maxW={isWideVersion ? "390px" : undefined}
		>
			{isWideVersion && featuredPost.length ? (
				<Flex w="100%" maxWidth="390px" flexDir="column">
					<Title
						text="Para Inspirar"
						fontSize="x-large"
						heightImage="20px"
						marginBottom="4"
						as='h3'
					/>
					<SlideEmphasisPost featuredPost={featuredPost} />
				</Flex>
			) : (<></>)}
			{isWideVersion && (
				<Flex
					marginTop={featuredPost.length ? "10" : "0"}
					flexDir="column"
					w="100%"
					maxWidth="350px"
				>
					<Title
						text="Temas"
						fontSize="x-large"
						heightImage="20px"
						marginBottom="4"
						as='h3'
					/>
					<UnorderedList
						rounded="md"
						borderWidth="2px"
						borderColor="gray.100"
						width="100%"
						marginLeft="0"
						padding="1rem 2.4rem"
						listStyleType="none"
					>
						{newsByCategory.map((category, index, arr) => (
							<ListItem
								key={category.secure_id}
								padding="1rem 0"
								display="flex"
								alignItems="center"
								borderBottomWidth={arr.length === index + 1 ? undefined : "1px"}
								borderBottomColor={
									arr.length === index + 1 ? undefined : "gray.200"
								}
							>
								<ListIcon
									as={TbCircleFilled}
									fontSize="0.5rem"
									color="#474595"
								/>
								<Flex justify="space-between" width="100%">
									<Text mb="-0.27rem" textStyle="headerSM" fontSize="1.1rem">
										{category.title}
									</Text>
									<Text mb="-0.27rem" textStyle="textSM" color="blackAlpha.500">
										{category.total_posts}
									</Text>
								</Flex>
							</ListItem>
						))}
					</UnorderedList>
				</Flex>
			)}
			<Flex marginTop="10" flexDir="column" w="100%" maxWidth="390px">
				<Title
					text="Siga-nos"
					fontSize="x-large"
					heightImage="20px"
					marginBottom="4"
					as='h3'
				/>
				<UnorderedList
					rounded="md"
					borderWidth="2px"
					borderColor="gray.100"
					width="100%"
					marginLeft="0"
					padding="1rem 1rem"
					spacing="4"
					listStyleType="none"
				>
					{socialMedias.map((socialMedia) => (
						<ListItem
							key={socialMedia.secure_id}
							display="flex"
							bg={socialMedia.background}
							rounded="30px"
							alignItems="center"
							padding="0.6rem 1.2rem"
							as={Link}
							href={socialMedia.link}
							target="_blank"
							passHref
							minH="14"
						>
							<Image
								src={socialMedia.icon.url}
								alt={socialMedia.icon.alt_text ?? socialMedia.name}
								maxW="25"
								objectFit="contain"
							/>
							<Text
								marginLeft="2"
								mb="-0.27rem"
								textStyle="headerSM"
								color="#FFF"
								fontWeight="black"
							>
								{socialMedia.followers}
							</Text>
							<Text
								marginLeft="3"
								mb="-0.27rem"
								textStyle="headerSM"
								color="#FFF"
								fontWeight="black"
							>
								SEGUIDORES
							</Text>
						</ListItem>
					))}
				</UnorderedList>
			</Flex>
			<Flex
				// minH="300px"
				maxW="390px"
				maxH="300"
				width="100%"
				marginTop="10"
				w="100%"
				justify="center"
			>
				<AdSlot id={'div-gpt-ad-1711110302742-0'} />
			</Flex>
		</Flex>
	);
}
