import {
	Box,
	Flex,
	HStack,
	useMediaQuery
} from "@chakra-ui/react";
import { useLayoutEffect } from "react";
import { AdSlot } from "~/components/Ads/AdSlot";
import { defineSlot } from '~/lib/dfp/index';
import { PostHomeProps } from "~/utils/Types/Home";
import { CardHighlight } from "./CardHighlight";

interface HighlightsProps {
	highlights: PostHomeProps[]
}

export const HighlightsPortal = ({ highlights }: HighlightsProps) => {
	useLayoutEffect(() => {
		defineSlot('/100074611/Bloco-1160x170', 'div-gpt-ad-1711110402742-0', [[1160, 170], [750, 100], [468, 60], [320, 50]], ['Home Destaques 1160x170'])
	}, [])

	return (
		<Flex bg="#f0f0f0" w="100%" py="3.75rem" direction="column" align="center">
			<AdSlot
				id={'div-gpt-ad-1711110402742-0'}
				customStyle={{
					marginBottom: "3.75rem"
				}}
			/>
			<HStack
				spacing={9}
				px={{ sm: "6", "xl": "inherit" }}
				maxW={{ sm: "100%", "xl": "1326px" }}
				overflowX={{ sm: "scroll", "xl": "inherit" }}
			>
				{highlights
					.map((highlight) => (
						<Box
							key={highlight.secure_id}
							h="7.813rem"
							flex={{ sm: "0 0 calc(100% / 1.1)", lg: "0 0 calc(100% / 2.25)", "xl": "1" }}
							sx={{
								"@media (max-width: 1400px)": {
									width: "19rem",
								},
								"@media (max-width: 1280px)": {
									width: "17rem",
								},
								"@media (max-width: 1200px)": {
									width: "15rem",
								},
								"@media (max-width: 1100px)": {
									width: "13rem",
								},
								"@media (max-width: 768px)": {
									width: "10rem",
								},
								"@media (max-width: 500px)": {
									width: "8rem",
								},
							}}
						>
							<CardHighlight highlight={highlight} />
						</Box>
					))}
			</HStack>
		</Flex>
	);
};
