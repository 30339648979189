import { Flex, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import { useLayoutEffect } from "react";
import { AdSlot } from "~/components/Ads/AdSlot";
import { defineSlot } from "~/lib/dfp";
import { NewsByCategoryProps, PostHomeProps, SocialMediaHomeProps } from "~/utils/Types/Home";
import MainHeightNews from "./MainHeightNews";
import SidebarHeightNews from "./SidebarHeightNews";

type HeightNewsProps = {
	breakingNews: PostHomeProps[]
	featuredPost: PostHomeProps[]
	socialMedias: SocialMediaHomeProps[]
	newsByCategory: NewsByCategoryProps[]
};

export function HeightNews({ breakingNews, featuredPost, newsByCategory, socialMedias }: HeightNewsProps) {
	const [isLargerThan990] = useMediaQuery("(min-width: 1150px)");
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	});

	useLayoutEffect(() => {
		defineSlot('/100074611/Bloco-1160x170', 'div-gpt-ad-1711110202742-0', [[1160, 170], [750, 100], [468, 60], [320, 50]], ['Home Notícias em Alta 1160x170'])
	}, [])

	return (
		<Flex
			flexDirection="column"
			align="center"
			as="section"
			minH="100vh"
			paddingY={isWideVersion ? "14" : "7"}
			paddingX="6"
			background="#FFF"
		>
			<Flex
				gap={isWideVersion ? "6" : "6"}
				flexDirection={isLargerThan990 ? "row" : "column"}
				width="100%"
				maxW="1326px"
				alignItems={isLargerThan990 ? undefined : "center"}
				justifyContent={isLargerThan990 ? "center" : "flex-start"}
			>
				<MainHeightNews breakingNews={breakingNews} />
				<SidebarHeightNews featuredPost={featuredPost} socialMedias={socialMedias} newsByCategory={newsByCategory} />
			</Flex>
			<AdSlot
				id={'div-gpt-ad-1711110202742-0'}
				customStyle={{
					marginTop: isWideVersion ? "3.5rem" : "1.75rem",
				}}
			/>
		</Flex>
	);
}
