import {
	Avatar, Button, Flex, Grid,
	HStack,
	Icon, Image, ListItem, Tag, TagLabel, Text,
	UnorderedList, useBreakpointValue, VStack
} from "@chakra-ui/react";
import Link from "next/link";
import { useMemo } from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";
import { PostHomeProps } from "~/utils/Types/Home";
import { PopoverShare } from "../PopoverShare";

interface CardBestOfTheWeekProps {
	isFirstCard?: boolean;
	bestWeek: PostHomeProps;
}

export function CardBestOfWeek({ isFirstCard, bestWeek }: CardBestOfTheWeekProps) {
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	})
	const url = FormatPortalLinks.formatPostLink({ post: bestWeek })

	const authorName = useMemo(() => {
		if (bestWeek.is_produced) {
			return bestWeek.produced
		}

		return `${bestWeek.author?.name.split(' ')[0]} ${bestWeek.author?.name.split(' ')[1] ?? ''}`
	}, [bestWeek])

	return (
		<Grid
			gridTemplateColumns={isFirstCard ? '1fr' : { sm: '1fr', '3xl': '1fr 1fr' }}
			w="100%"
			gap={4}
		>
			<Flex
				as={Link}
				href={url}
				width={"100%"}
				position="relative"
			>
				<Image
					width="100%"
					height="100%"
					objectFit="cover"
					src={bestWeek.thumbnail?.url}
					alt={bestWeek.thumbnail?.alt_text ?? bestWeek.meta_title}
					rounded="10"
				/>
				{isFirstCard && !!bestWeek.views && bestWeek.views >= 500 && (
					<Button
						size="sm"
						fontSize="md"
						rounded="full"
						py={6}
						px={4}
						cursor="default"
						position="absolute"
						left="20px"
						top="20px"
						background="#7448DC"
						_hover={{
							background: "#7448DC"
						}}
					>
						<Icon as={BsFillLightningChargeFill} fontSize={"lg"} color={"#FFF"} />
					</Button>
				)}
				<Flex
					position="absolute"
					top="14px"
					right="14px"
					gap="3"
				>
					{!!bestWeek.tags && bestWeek.tags.length && !bestWeek.is_sponsored && bestWeek.tags.split(',').splice(0, isFirstCard ? 2 : 1).map((item) => (
						<Tag
							key={item}
							zIndex="2"
							size="lg"
							padding="0 0,5rem"
							borderRadius='full'
							variant='solid'
							background="#0360D7"
							width="min-content"
						>
							<TagLabel mb="-0.15rem">
								{item}
							</TagLabel>
						</Tag>
					))}
					{!!bestWeek.is_sponsored && (
						<Tag
							size="md"
							borderRadius='full'
							variant='solid'
							background="gray"
							right="20px"
							top="10px"
						>
							<TagLabel>Patrocinado</TagLabel>
						</Tag>
					)}
				</Flex>
			</Flex>
			<VStack spacing="0" alignItems="flex-start">
				<VStack align="flex-start">
					<Link href={url} passHref>
						<Text
							lineHeight="6"
							fontSize="x-large"
							color="#000"
							as="h4"
							fontWeight="black"
							noOfLines={3}
						>
							{bestWeek.title}
						</Text>
					</Link>
				</VStack>
				<Flex flexDirection={isWideVersion ? "column" : "column-reverse"}>
					{!isFirstCard &&
						<Text color="#575757" noOfLines={1}>
							{bestWeek.excerpt}
						</Text>}
					{isFirstCard ? (
						<Flex marginTop="4">
							<Avatar
								size="sm"
								name={bestWeek.is_produced ? bestWeek.produced : bestWeek?.author?.name}
								src={bestWeek.is_produced ? "" : bestWeek?.author?.avatar?.url}
								css={{
									'&>div': {
										marginTop: "2px"
									}
								}}
							/>
							<Text
								marginLeft="2"
								mb="-0.35rem"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								POR {bestWeek.is_produced ? bestWeek.produced : bestWeek.author?.name}
							</Text>
							<UnorderedList display="flex" listStyleType="none">
								<ListItem
									marginLeft="-0.5"
									display="flex"
									alignItems="center"
								>
									<PopoverShare linkHref={url} postId={bestWeek.secure_id} />
								</ListItem>
							</UnorderedList>
						</Flex>
					) : (
						<Flex marginTop="4">
							<Avatar
								css={{
									'&>div': {
										marginTop: "2px"
									}
								}}
								size="xs"
								name={authorName}
							/>
							<HStack>
								<Text
									color="#575757"
									marginLeft="2"
									display="flex"
									justifyContent="center"
									alignItems="center"
									lineHeight="1.2"
								>
									POR {authorName}
								</Text>
								<PopoverShare linkHref={url} postId={bestWeek.secure_id} />
							</HStack>
							{/* <UnorderedList display="flex" listStyleType="none">
								<ListItem
									marginLeft="-0.5"
									display="flex"
									alignItems="center"
								>
									<PopoverShare linkHref={url} postId={bestWeek.secure_id} />
								</ListItem>
							</UnorderedList> */}
						</Flex>
					)}
				</Flex>
			</VStack>
		</Grid>
	)
}
