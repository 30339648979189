import {
	Avatar,
	Box,
	HStack,
	Text,
	Icon,
	Flex
} from '@chakra-ui/react'
import { HiArrowTrendingUp } from "react-icons/hi2"
import { FaCalendarAlt, FaCommentAlt } from "react-icons/fa"


interface AuthorProps {
	author?: string;
	avatar?: string;
	views?: number;
	date?: string;
	comments?: number;
	share?: boolean
}

export const AuthorPortal = ({ author, avatar, views, comments, date, share = false }: AuthorProps) => {
	return (
		<HStack>
			<HStack>
				<Avatar
					name={author}
					size="sm"
					src={avatar}
					css={!avatar ? {
						'&>div': {
							marginTop: "2px"
						}
					} : undefined
					}
				/>
				<Text fontSize="md" color="white" pt="1">{author}</Text>
			</HStack>
			{views &&
				<>
					<Box rounded="full" w={1} h={1} bg="#ccc" />
					<HStack>
						<Icon as={HiArrowTrendingUp} color="green" fontSize="2xl" />
						<Text fontSize="md" color="white" pt="1">{views} views</Text>
					</HStack>
				</>
			}
			{date &&
				<>
					<Box rounded="full" w={1} h={1} bg="#ccc" />
					<HStack>
						<Icon as={FaCalendarAlt} color="white" fontSize="xl" />
						<Text fontSize="md" color="white" pt="1">{date}</Text>
					</HStack>
				</>
			}
			{comments &&
				<>
					<Box rounded="full" w={1} h={1} />
					<Flex justifyContent="center" alignItems="center">
						<Icon as={FaCommentAlt} color="black" fontSize="3xl" position="absolute" zIndex="2" />
						<Text fontSize="md" color="white" position="relative" zIndex="4">{comments}</Text>
					</Flex>
				</>
			}
		</HStack >
	)
}
