import {
	Box,
	Flex,
	Grid,
	GridItem,
	HStack,
	Skeleton,
	SkeletonCircle,
	SkeletonText,
	VStack,
	useBreakpointValue,
	useMediaQuery,
} from "@chakra-ui/react";

import { FC } from "react";

export const HomeLoadinScreen: FC = () => {
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	});

	const [isLargerThan1020] = useMediaQuery("(min-width: 1050px)");
	const [isLargerThan1400] = useMediaQuery("(min-width: 1400px)");
	const [isLargerThan1300] = useMediaQuery("(min-width: 1300px)");

	return (
		<>
			<Flex
				bg="#f0f0f0"
				w="100%"
				py="3.75rem"
				direction="column"
				align="center"
				sx={{
					"@media (max-width: 768px)": {
						display: "none",
					},
				}}
			>
				<HStack spacing={6} px="5" mt="3.75rem">
					{Array(4)
						.fill(1)
						.filter(
							(_, index) =>
								index <= (isLargerThan1400 ? 3 : isLargerThan1020 ? 2 : 1)
						)
						.map((_, index) => (
							<Box key={index} w="19rem" h="7.813rem">
								<HStack>
									<SkeletonCircle size="28" />
									<SkeletonText
										w="50%"
										noOfLines={3}
										spacing="4"
										skeletonHeight="2"
									/>
								</HStack>
							</Box>
						))}
				</HStack>
			</Flex>
			<Flex w="100%" h="41.875rem" maxH="41.875rem" position="relative">
				<Skeleton width="100%" height="100%" />
			</Flex>
			<VStack
				as="section"
				minHeight="100vh"
				background="#FFF7F8"
				alignItems="center"
				justifyContent="center"
				paddingBottom="14"
				paddingLeft="6"
				spacing="16"
				paddingRight="6"
			>
				<VStack
					maxWidth="1326px"
					width="100%"
					alignItems="center"
					gap={7}
					marginTop={isWideVersion ? "20" : "14"}
				>
					<Flex
						flexDir="column"
						gap={"4"}
						width="100%"
						maxW={
							isLargerThan1300 ? undefined : isWideVersion ? "866px" : "417px"
						}
					>
						<SkeletonText w="30%" noOfLines={1} skeletonHeight="8" />
						<Flex
							gap={8}
							flexDirection={isLargerThan1300 ? "row" : "column"}
							width="100%"
						>
							<Grid
								templateRows={"repeat(2, minmax(300px, auto))"}
								templateColumns={
									isWideVersion ? "repeat(2, auto)" : "repeat(1, auto)"
								}
								gap={8}
								w="100%"
							>
								{Array(3)
									.fill(1)
									.map((_, index) => (
										<GridItem
											key={index}
											colSpan={1}
											rowSpan={isWideVersion && index === 0 ? 2 : 1}
											position="relative"
											maxW="417px"
										>
											<Skeleton
												width="100%"
												objectFit="cover"
												height={
													isWideVersion && index === 0 ? "540px" : "220px"
												}
												rounded="10"
											/>
											<SkeletonText
												mt="4"
												noOfLines={3}
												spacing="4"
												skeletonHeight="2"
											/>
										</GridItem>
									))}
							</Grid>
							<Flex
								flexDir="column"
								width="100%"
								align="center"
								justify={isLargerThan1300 ? undefined : "center"}
								maxWidth={isLargerThan1300 ? "390px" : undefined}
								gap="8"
								flex="1"
								flexDirection={
									isLargerThan1300 ? "column" : isWideVersion ? "row" : "column"
								}
							>
								<HStack
									width={isLargerThan1300 ? "390px" : "100%"}
									height="100%"
									minH="300px"
									maxW="390px"
									maxH="300"
									marginTop={isWideVersion ? undefined : "4"}
								>
									<Skeleton w="100%" h="100%" />
								</HStack>

								<HStack
									width={isLargerThan1300 ? "390px" : "100%"}
									height="100%"
									minH="300px"
									maxW="390px"
									maxH="300"
									marginTop={isWideVersion ? undefined : "4"}
								>
									<Skeleton w="100%" h="100%" />
								</HStack>
							</Flex>
						</Flex>
					</Flex>
				</VStack>
			</VStack>
		</>
	);
};
