import {
	Accordion,
	Button,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CookieItem from "../CookieItem";

interface HandlePersonalizeProps {
	required_cookies: boolean;
	savarejo_cookie_analytics: boolean;
	savarejo_cookie_third: boolean;
}

interface CookiesModalProps {
	isOpen: boolean;
	onClose: () => void;
	handleSave: ({}: HandlePersonalizeProps) => void;
}

export default function CookiesModal({
	isOpen,
	onClose,
	handleSave,
}: CookiesModalProps) {
	const [cookieThird, setCookieThird] = useState(true);
	const [cookieAnalytics, setCookieAnalytics] = useState(true);
	const [isLargerThan768] = useMediaQuery('(min-width: 766px)')
	const [isLargerThan375] = useMediaQuery('(min-width: 374px)')

	const saveCookiesAndClose = () => {
		handleSave({
			required_cookies: true,
			savarejo_cookie_analytics: cookieAnalytics,
			savarejo_cookie_third: cookieThird,
		});
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
			<ModalOverlay />
			<ModalContent borderRadius={0} minW={isLargerThan768 ? "600px" : "280px"} maxH="500px" >
				<ModalHeader bg="#e21350" color="#fff" fontSize={isLargerThan375 ? "18px" : "12px"}>
					CONFIGURAÇÕES DE COOKIES
				</ModalHeader>
				<ModalCloseButton color="#fff" />
				<ModalBody>
					<Text fontWeight="500" fontSize={isLargerThan375 ? "14px" : "12px"}  mb={2}>
						Você pode alterar as configurações de cookies ativando ou
						desativando os cookies analíticos e de terceiros aqui. Você não pode
						desativar os cookies estritamente necessários. Você pode descobrir
						mais sobre cookies{" "}
						<Link
							color="#e21350"
							target="_blank"
							href={`${process.env.NEXT_PUBLIC_HOST_URL}/aviso-de-cookies`}
						>
							aqui
						</Link>
						.
					</Text>
					<Text fontWeight="500" fontSize={isLargerThan375 ? "14px" : "12px"} mb={2}>
						Observe que alguns recursos do site não funcionarão sem cookies.
					</Text>

					<Accordion allowMultiple>
						<CookieItem
							title="Cookies Necessários/Obrigatórios"
							description="Esses cookies são essenciais para todo o site e para 
							que seus recursos funcionem corretamente. 
							Garantindo funcionalidades básicas e recursos de segurança do site."
							color="switchInactive"
							switchProps={{ isChecked: true }}
						/>
						<CookieItem
							title="Cookies de Funcionalidades"
							description="Esses cookies coletam informações sobre como você usa o site e 
							nos permitem mapear o comportamento de visitantes e pesquisas e ajustar nossos 
							serviços de acordo com as descobertas. Isso nos permite melhorar ainda mais nossos 
							serviços para você como visitante. Usamos cookies analíticos, como o Google Analytics, 
							que tem como finalidade: Entender como os visitantes interagem com o site, ajudam a fornecer 
							informações sobre métricas de número de visitantes, taxa de rejeição, origem de tráfego, etc."
							color="switchActive"
							switchProps={{
								defaultChecked: cookieAnalytics,
								onChange: (e) => setCookieAnalytics(e.target.checked),
							}}
						/>
						<CookieItem
							title="Cookies de Marketing"
							description="Esses cookies são instalados por terceiros, por exemplo, 
							redes sociais. Seu principal objetivo é integrar o conteúdo de mídia 
							social em nosso site, como plug-ins sociais."
							color="switchActive"
							switchProps={{
								defaultChecked: cookieThird,
								onChange: (e) => setCookieThird(e.target.checked),
							}}
						/>
					</Accordion>
				</ModalBody>

				<ModalFooter px={0} borderTop="1px solid #d2d2d2">
					<Button
						bg="backgroundHeader"
						color="#fff"
						mr={3}
						onClick={saveCookiesAndClose}
						borderRadius="none"
					>
						FECHAR
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
