import { useQuery } from "@tanstack/react-query"
import { api } from "~/services/apiClient"
import { NewsByCategoryProps, PostHomeProps } from "~/utils/Types/Home"

type GetHomePostsResponse = {
	shelf: PostHomeProps[]
	news: PostHomeProps[]
	highlights: PostHomeProps[]
	best_week: PostHomeProps[]
	topics_day: PostHomeProps[]
	featured_post: PostHomeProps[]
	breaking_news: PostHomeProps[]
	news_by_category: NewsByCategoryProps[]
}

type GetHomePostsProps = {
}

export async function getHomePosts({ }: GetHomePostsProps): Promise<GetHomePostsResponse> {
	const response = await api.get('/v1/home-posts')

	return {
		...response.data
	}
}

export function useHomePosts({ }: GetHomePostsProps) {
	return useQuery(['Portal', 'HomePosts'], () => getHomePosts({}), {
		staleTime: 1000 * 60 * 60 // 60 minutos
	})
}
