import { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import Script from "next/script";
import { PureComponent, useEffect, useRef, useState } from "react";
import OneSignal from "react-onesignal";

import { useHomePosts } from "~/hooks/Home/useHomePosts";
import { setupApiClient } from "~/services/api";
import { HomeDataProps } from "~/utils/Types/Home";

import CookiesAllow from "~/components/global/CookiesAllow";
import { LiveWindow } from "~/components/global/LiveWindow";
import { Attacked } from "~/components/Portal/Attacked";
import { BestOfTheWeek } from "~/components/Portal/BestOfTheWeek";
import { CoverPortal } from "~/components/Portal/Cover";
import { Footer } from "~/components/Portal/Footer";
import { HeaderPortal } from "~/components/Portal/Header";
import { HeightNews } from "~/components/Portal/HeightNews";
import { HighlightsPortal } from "~/components/Portal/Highlights";
import { HomeLoadinScreen } from "~/components/Portal/HomeLoadinScreen";
import { ShelfAndSolution } from "~/components/Portal/ShelfAndSolution";
import { TopicsOfTheDay } from "~/components/Portal/TopicsOfTheDay";
import { Updates } from "~/components/Portal/Updates";
import { PortalVideos } from "~/components/Portal/Videos";
import { WithSSRValidationAllStatic } from "~/utils/Validator/WithSSRValidationAll";
import { Box } from "@chakra-ui/react";

interface HomeProps {
	dataHome: HomeDataProps;
}

const Home: NextPage<HomeProps> = ({ dataHome }) => {
	const { data, isLoading } = useHomePosts({});

	const [visibleComponents, setVisibleComponents] = useState<{ [key: string]: boolean }>({});
	const componentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

	function runOneSignal() {
		OneSignal.init({
			appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID as string,
			allowLocalhostAsSecureOrigin: true,
			persistNotification: false,
			// safari_web_id: process.env.NEXT_PUBLIC_SAFARI_ONESIGNAL_ID as string,
			promptOptions: {
				slidedown: {
					enabled: true,
					actionMessage:
						"Gostaríamos de mostrar notificações sobre as últimas notícias e atualizações.",
					acceptButtonText: "Aceitar",
					cancelButtonText: "Recusar",
					prompts: [
						{
							type: "push",
							autoPrompt: true,
							text: {
								default: {
									actionMessage:
										"Gostaríamos de mostrar notificações sobre as últimas notícias e atualizações.",
									acceptButton: "Aceitar",
									cancelButton: "Aceitar",
								},
							},
						},
					],
				},
			},
		}).then(() => {
			OneSignal.Slidedown.promptPush({
				slidedownPromptOptions: {
					categoryOptions: {
						updateMessage:
							"Assine nossas notificações para obter as últimas notícias e atualizações. Você pode desativar a qualquer momento.",
						errorButtonText: "Depois",
						negativeUpdateButton: "Depois",
						positiveUpdateButton: "Aceitar",
						savingButtonText: "Salvar",
						tags: [],
					},
				},
			});
		});
	}

	useEffect(() => {
		if (process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID) {
			runOneSignal();
		}
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const componentName = entry.target.getAttribute('data-name');

					if (componentName && entry.isIntersecting) {
						setVisibleComponents((prev) => {
							if (!prev[componentName]) {
								return {
									...prev,
									[componentName]: true,
								};
							}
							return prev;
						});

						// Desobservar o componente atual quando ele se tornar visível
						observer.unobserve(entry.target);
					}
				});
			},
			{
				rootMargin: '100px', // Adiciona uma margem para antecipar o carregamento
				threshold: 0.1, // O componente começa a carregar quando 10% está visível
			}
		);

		const componentNames = ['TopicsOfTheDay', 'HeightNews', 'ShelfAndSolution', 'Attacked', 'PortalVideos', 'BestOfTheWeek', 'outhers'];

		componentNames.forEach((name) => {
			const ref = componentRefs.current[name];
			if (ref && !visibleComponents[name]) {
				observer.observe(ref); // Observa o componente se ainda não estiver visível
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [visibleComponents]);

	return (
		<>
			{!!dataHome.settings && (
				<Head>
					<link
						rel="shortcut icon"
						href={dataHome.settings.favicon.url}
						type="image/png"
					/>
					<link
						rel="canonical"
						href={process.env.NEXT_PUBLIC_HOST_URL}
					/>
					<title>{dataHome.settings.head_name}</title>
					<meta property="og:site_name" content={dataHome.settings.head_name ?? "SA+ Ecossistema de Varejo"}></meta>
					{dataHome.settings.meta_title && (
						<>
							<meta name="title" content={dataHome.settings.meta_title}></meta>
							<meta property="og:title" content={dataHome.settings.meta_title} />
							<meta name="twitter:title" content={dataHome.settings.meta_title} />
						</>
					)}
					{dataHome.settings.meta_description && (
						<>
							<meta name="description" content={dataHome.settings.meta_description}></meta>
							<meta property="og:description" content={dataHome.settings.meta_description} />
							<meta name="twitter:description" content={dataHome.settings.meta_description} />
						</>
					)}
					<meta property="og:url" content={`${process.env.NEXT_PUBLIC_HOST_URL}`}></meta>
					<meta property="og:type" content="website" />
				</Head>
			)}
			<Script
				src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
				async
			/>

			<HeaderPortal
				news={dataHome.last_news}
				menuMain={dataHome.menuMain}
				subMain={dataHome.subMain}
				socialMedias={dataHome.social_medias}
			/>

			{!!dataHome.youtubeLive &&
				!!dataHome.youtubeLive?.items.length &&
				dataHome.youtubeLive?.items[0].snippet.liveBroadcastContent !==
				"none" && <LiveWindow items={dataHome.youtubeLive.items} />}

			{isLoading && <HomeLoadinScreen />}

			{!!data && !!data.highlights && data.highlights.length && (
				<HighlightsPortal highlights={data.highlights} />
			)}

			{!!data && !!data.news.length && <CoverPortal news={data.news} />}

			<Box
				ref={(el) => (componentRefs.current['TopicsOfTheDay'] = el)}
				data-name="TopicsOfTheDay"
				minH={(!data || !!data.topics_day) ? 300 : undefined}
			>
				{!!data && !!data.topics_day.length && visibleComponents['TopicsOfTheDay'] && (
					<TopicsOfTheDay topicsDay={data.topics_day} />
				)}
			</Box>

			<Box
				ref={(el) => (componentRefs.current['HeightNews'] = el)}
				data-name="HeightNews"
				minH={300}
			>
				{!!data && visibleComponents['HeightNews'] && (
					<HeightNews
						breakingNews={data.breaking_news}
						featuredPost={data.featured_post}
						socialMedias={dataHome.social_medias}
						newsByCategory={data.news_by_category}
					/>
				)}
			</Box>
			<Box
				ref={(el) => (componentRefs.current['ShelfAndSolution'] = el)}
				data-name="ShelfAndSolution"
				minH={300}
			>
				{!!data && visibleComponents['ShelfAndSolution'] && <ShelfAndSolution shelf={data.shelf} />}
			</Box>
			<Box
				ref={(el) => (componentRefs.current['Attacked'] = el)}
				data-name="Attacked"
				minH={300}
			>
				{!!data && visibleComponents['Attacked'] && <Attacked newsByCategoryProps={data.news_by_category} />}
			</Box>
			<Box
				ref={(el) => (componentRefs.current['PortalVideos'] = el)}
				data-name="PortalVideos"
				minH={(dataHome.videos.length) ? 300 : undefined}
			>
				{!!dataHome.videos.length && visibleComponents['PortalVideos'] && <PortalVideos videos={dataHome.videos} />}
			</Box>

			<Box
				ref={(el) => (componentRefs.current['BestOfTheWeek'] = el)}
				data-name="BestOfTheWeek"
				minH={300}
			>
				{!!data && visibleComponents['BestOfTheWeek'] && (
					<BestOfTheWeek
						bestWeek={data.best_week}
						hotCategories={dataHome.hot_categories}
					/>
				)}
			</Box>
			<Box
				ref={(el) => (componentRefs.current['outhers'] = el)}
				data-name="outhers"
				minH={300}
			>
				{visibleComponents['outhers'] && (
					<>
						<Updates />
						<Footer
							footer_menu_left={dataHome.footer_menu_left}
							footer_menu_right={dataHome.footer_menu_right}
							socialMedias={dataHome.social_medias}
						/>
						<CookiesAllow
							pagePrivacyWarning={dataHome.settings?.page_privacy_warning}
							pageCookiesWarning={dataHome.settings?.page_cookies_warning}
							pageTermsOfUse={dataHome.settings?.page_terms_of_use}
						/>
					</>
				)}
			</Box>
		</>
	);
};

export default Home;

export const getStaticProps: GetStaticProps = WithSSRValidationAllStatic(async () => {
	const apiServer = setupApiClient(undefined);

	const { data: dataHome } = await apiServer.get(`/v1/home`);
	return {
		props: {
			dataHome,
		},
		revalidate: 10, //60 * 1 * 1, //1 minuto
	};
});
