import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Switch,
	useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

interface SwitchProps {
	defaultChecked?: boolean;
	isChecked?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CookieItemProps {
	title: string;
	description: string;
	color: string;
	switchProps?: SwitchProps;
}

export default function CookieItem({
	title,
	description,
	color,
	switchProps,
}: CookieItemProps) {
	const [isLargerThan375] = useMediaQuery("(min-width: 374px)");

	return (
		<AccordionItem border="none">
			<Flex
				bg="#f4f4f4"
				borderRadius={5}
				align="center"
				justify="space-between"
				w="100%"
				pr={4}
				pl={0}
				mb={2}
			>
				<AccordionButton _hover={{ background: "#f4f4f4" }}>
					<AccordionIcon />
					<Box
						as="span"
						flex="1"
						textAlign="left"
						fontWeight="bold"
						fontSize={isLargerThan375 ? "14px" : "12px"}
					>
						{title}
					</Box>
				</AccordionButton>
				<Switch
					size={isLargerThan375 ? "lg" : "md"}
					colorScheme={color}
					{...switchProps}
				/>
			</Flex>
			<AccordionPanel pb={4} fontSize={isLargerThan375 ? "14px" : "12px"}>
				{description}
			</AccordionPanel>
		</AccordionItem>
	);
}
