import { Avatar, Flex, Icon, Image, Stack, Tag, TagLabel, Text, useBreakpointValue } from "@chakra-ui/react";
import Link from "next/link";
import { FaCommentAlt } from "react-icons/fa";
import { TbCircleFilled } from "react-icons/tb";
import { GetColorTags } from "~/utils/GetColorTags";
import { PostHomeProps } from "~/utils/Types/Home";
import { ButtonReadMore } from "../Buttons/ButtonReadMore";
import { PopoverShare } from "../PopoverShare";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";
import ImageNext from 'next/image'

type CardHeightNewsProps = {
	post: PostHomeProps
	index: number
}

export function CardHeightNews({ post, index }: CardHeightNewsProps) {
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	})

	return (
		<Flex
			marginTop="12"
			flexDir={isWideVersion ? "row" : "column"}
			width="100%"
			maxWidth={isWideVersion ? undefined : "405px"}
			flexDirection={isWideVersion ? index % 2 === 0 ? "row-reverse" : "row" : "column"}
		>
			<Stack
				as={Link}
				href={FormatPortalLinks.formatPostLink({ post })}
				width="100%"
				maxW="405px"
				position="relative"
			>
				{/* <Image
					rounded="lg"
					height={"350px"}
					width="100%"
					maxWidth="405px"
					objectFit="cover"
					src={post.thumbnail?.url}
					alt={post.thumbnail?.alt_text ?? post.meta_title}
				/> */}
				<ImageNext
					style={{
						borderRadius: 8,
						width: '100%',
						maxHeight: '350px',
						height: '350px',
						objectFit: 'cover',
					}}
					width={405}
					height={350}
					src={post.thumbnail?.url ?? ''}
					alt={post.thumbnail?.alt_text ?? post.meta_title ?? ''}
				/>
				{!!post.tags && !post.is_sponsored && post.tags.length > 0 && (
					<Tag
						size="md"
						borderRadius='full'
						variant='solid'
						position="absolute"
						background={GetColorTags(0)}
						right="20px"
						top="10px"
					>
						<TagLabel>{post.tags.split(',')[0]}</TagLabel>
					</Tag>
				)}
				{!!post.is_sponsored && (
					<Tag
						size="md"
						borderRadius='full'
						variant='solid'
						position="absolute"
						background="gray"
						right="20px"
						top="10px"
					>
						<TagLabel>Patrocinado</TagLabel>
					</Tag>
				)}
			</Stack>
			<Flex
				paddingTop={isWideVersion ? "8" : "4"}
				paddingLeft={isWideVersion ? "8" : "0"}
				paddingBottom={isWideVersion ? "6" : "0"}
				flexDir="column"
				w="100%"
			>
				<Link
					href={FormatPortalLinks.formatPostLink({ post })}
				>
					<Text
						textStyle="headerXL"
						fontSize="2rem"
						as={"h4"}
					>
						{post.title}
					</Text>
				</Link>
				<Flex marginTop="3" align="center">
					<Avatar
						size="xs"
						name={post.is_produced ? post.produced : post?.author?.name}
						src={post.is_produced ? "" : post?.author?.avatar?.url}
						css={{
							'&>div': {
								marginTop: "2px"
							}
						}}
					/>
					<Text
						marginLeft="2"
						mb="-0.18rem"
						textStyle="textSM"
						color='blackAlpha.700'
						textTransform="uppercase"
					>
						POR {post.is_produced ? post.produced : post.author?.name}
						{isWideVersion &&
							<Icon
								as={TbCircleFilled}
								color='blackAlpha.400'
								fontSize="6px"
								marginLeft="3"
							/>
						}
					</Text>
					<Icon
						marginLeft="8"
						as={FaCommentAlt}
						color='blackAlpha.700'
						marginRight="1"
						fontSize="lg"
					/>
				</Flex>
				<Text textStyle="textLG" noOfLines={5} lineHeight="6" fontSize="xl" color="blackAlpha.700" marginTop="8" w="100%">
					{post.excerpt}
				</Text>
				<Flex align="center" marginTop="8">
					<ButtonReadMore
						text='VEJA MAIS'
						linkHref={FormatPortalLinks.formatPostLink({ post })}
						background="#E3E1E2"
						fontWeight="semibold"
						color="blackAlpha.900"
						marginRight="6"
					/>
					<PopoverShare linkHref={FormatPortalLinks.formatPostLink({ post })} postId={post.secure_id} />
				</Flex>
			</Flex>
		</Flex >
	)
}
