import {
	Icon,
	Link as ChakraLink,
	LinkProps as ChakraLinkProps,
	Text,
	HStack
} from '@chakra-ui/react'
import { ElementType } from 'react'

import NextLink from "next/link"

interface LinkShareProps extends ChakraLinkProps {
	icon?: ElementType
	children: string
	href: string
}

export const LinkShare = ({ icon, children, href, ...rest }: LinkShareProps) => {
	return (
		<ChakraLink
			as={NextLink}
			href={href}
			w="100%"
			display="flex"
			target="_blank"
			// bg={isActive ? 'pink.50' : 'transparent'}
			// _hover={{
			// 	textDecoration: 'none',
			// 	bg: 'pink.50'
			// }}
			{...rest}
		>
			{icon && (
				<Icon as={icon} fontSize="lg" mr="2" />
			)}
			<Text fontWeight="medium">
				{children}
			</Text>
		</ChakraLink>
	)
}
