import { Avatar, Button, Flex, GridItem, HStack, Icon, Image, Tag, TagLabel, Text } from "@chakra-ui/react";
import Link from "next/link";
import { RiFireFill } from "react-icons/ri";
import { PostHomeProps } from "~/utils/Types/Home";
import { ButtonReadMore } from "../Buttons/ButtonReadMore";
import { PopoverShare } from "../PopoverShare";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";

interface CardAttackedFirstItemProps {
	post: PostHomeProps
}

export function CardAttackedFirstItem({ post }: CardAttackedFirstItemProps) {
	return (
		<GridItem
			background="#FAFAFA"
			colSpan={3}
			rowSpan={2}
			display="flex"
			rounded="lg"
		>
			{!!post.shares && post.shares >= 100 && (
				<Button
					size="sm"
					fontSize="md"
					rounded="full"
					py={6}
					px={4}
					top="25px"
					left="40px"
					cursor="default"
					background="#E81A17"
					position="absolute"
					_hover={{
						background: "#E81A17"
					}}
				>
					<Icon as={RiFireFill} fontSize={"lg"} color={"#FFF"} />
				</Button>
			)}
			<Flex
				h="100%"
				as={Link}
				href={FormatPortalLinks.formatPostLink({ post })}
				position="relative"
			>
				{!!post.is_sponsored && (
					<Tag
						size="md"
						borderRadius='full'
						variant='solid'
						position="absolute"
						background="gray"
						right="20px"
						top="10px"
					>
						<TagLabel>Patrocinado</TagLabel>
					</Tag>
				)}
				<Image
					borderStartRadius="lg"
					maxW="700px"
					height="473px"
					objectFit="cover"
					margin="0"
					src={post.thumbnail?.url}
					alt={post.thumbnail?.alt_text ?? post.meta_description}
				/>
			</Flex>
			<Flex
				paddingTop="16"
				paddingLeft="16"
				pr={6}
				flexDirection="column"
			>
				<HStack spacing="3">
					{!!post.tags && post.tags.length && post.tags.split(',').splice(0, 4).map((item) => (
						<Tag
							key={item}
							size="lg"
							borderRadius='full'
							variant='solid'
							background="#0360D7"
							width="min-content"
						>
							<TagLabel mb="-0.15rem">{item}</TagLabel>
						</Tag>
					))}
				</HStack>
				<Link
					href={FormatPortalLinks.formatPostLink({ post })}
				>
					<Text
						as={"h4"}
						marginTop="8"
						textStyle="headerXL"
						maxWidth="521px"
						fontSize="2.2rem"
						noOfLines={2}
					>
						{post.title}
					</Text>
				</Link>
				<Flex
					marginTop="3"
					alignItems="center"
				>
					<Avatar
						size="xs"
						name={post.is_produced ? post.produced : post?.author?.name}
						src={post.is_produced ? "" : post?.author?.avatar?.url}
						css={{
							'&>div': {
								marginTop: "2px"
							}
						}}
					/>
					<Text
						marginLeft="2"
						mb="-0.31rem"
						textTransform="uppercase"
					>
						POR {!!post.is_produced ? post.produced : post.author?.name}
					</Text>
					<PopoverShare linkHref={FormatPortalLinks.formatPostLink({ post })} postId={post.secure_id} />
				</Flex>
				<Text marginTop="4" textStyle="textLG" color="blackAlpha.700" noOfLines={5}>
					{post.excerpt}
				</Text>
				<ButtonReadMore
					text='VEJA MAIS'
					linkHref={FormatPortalLinks.formatPostLink({ post })}
					background="#E3E1E2"
					marginTop="8"
					fontWeight="semibold"
					color="blackAlpha.900"
				/>
			</Flex>
		</GridItem>
	)
}
