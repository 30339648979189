import {
	Button,
	Flex,
	Link as ChakraLink,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import CookiesModal from "./CookiesModal";
import { parseCookies, setCookie } from "nookies";

interface HandlePersonalizeProps {
	required_cookies: boolean;
	savarejo_cookie_analytics: boolean;
	savarejo_cookie_third: boolean;
}

interface CookiesAllowProps {
	pagePrivacyWarning?: string
	pageCookiesWarning?: string
	pageTermsOfUse?: string
}

export default function CookiesAllow({ pagePrivacyWarning = '/aviso-de-privacidade', pageCookiesWarning = '/aviso-de-cookies', pageTermsOfUse = '/termos-de-uso' }: CookiesAllowProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [showCookiesModal, setShowCookiesModal] = useState(false);

	useEffect(() => {
		const { "@SAVarejo:required_cookies": required_cookies } = parseCookies();
		const { "@SAVarejo:savarejo_cookie_third": savarejo_cookie_third } =
			parseCookies();
		const { "@SAVarejo:savarejo_cookie_analytics": savarejo_cookie_analytics } =
			parseCookies();

		if (
			!!required_cookies &&
			!!savarejo_cookie_third &&
			!!savarejo_cookie_analytics
		) {
			setShowCookiesModal(false);
		} else {
			setShowCookiesModal(true);
		}
	}, []);

	const handleSave = ({
		required_cookies = true,
		savarejo_cookie_analytics = false,
		savarejo_cookie_third = false,
	}: HandlePersonalizeProps) => {
		setCookie(
			undefined,
			"@SAVarejo:required_cookies",
			`allowed=${required_cookies}`,
			{
				maxAge: 86400 * 365, //  1 year
				path: "/",
			}
		);
		setCookie(
			undefined,
			"@SAVarejo:savarejo_cookie_third",
			`allowed=${savarejo_cookie_third}`,
			{
				maxAge: 86400 * 365, //  1 year
				path: "/",
			}
		);
		setCookie(
			undefined,
			"@SAVarejo:savarejo_cookie_analytics",
			`allowed=${savarejo_cookie_analytics}`,
			{
				maxAge: 86400 * 365, //  1 year
				path: "/",
			}
		);
		setShowCookiesModal(false);
	};

	return (
		<>
			{showCookiesModal && (
				<Stack
					minH="100px"
					bg="backgroundHeader"
					width="100%"
					pos="fixed"
					zIndex={1000}
					left={0}
					bottom={0}
					justify="center"
					align="center"
					py={6}
					px={4}
				>
					<Text color="#fff" textAlign="justify" maxW={930}>
						Utilizamos cookies e dados de navegação para proporcionar uma melhor
						experiência durante o uso do site, além de personalizar conteúdo e
						anúncios. Se desejar, você pode{" "}
						<Text
							as="span"
							textDecor="0.8px underline"
							onClick={onOpen}
							cursor="pointer"
						>
							Personalizar suas preferências
						</Text>{" "}
						ou conferir nossa{" "}
						<ChakraLink
							as={Link}
							textDecor="0.8px underline"
							target="_blank"
							href={pagePrivacyWarning}
						>
							Aviso de Privacidade
						</ChakraLink>
						,{" "}
						<ChakraLink
							as={Link}
							textDecor="0.8px underline"
							target="_blank"
							href={pageCookiesWarning}
						>
							Aviso de Cookies
						</ChakraLink>{" "}
						e{" "}
						<ChakraLink
							as={Link}
							textDecor="0.8px underline"
							target="_blank"
							href={pageTermsOfUse}
						>
							Termos de Uso
						</ChakraLink>
						.
					</Text>
					<Flex gap={6} w="100%" justify="center">
						<Button
							color="backgroundHeader"
							textDecor="underline"
							borderRadius="none"
							onClick={onOpen}
							fontWeight="bold"
						>
							PERSONALIZAR
						</Button>
						<Button
							color="backgroundHeader"
							textDecor="underline"
							borderRadius="none"
							fontWeight="bold"
							onClick={() =>
								handleSave({
									required_cookies: true,
									savarejo_cookie_analytics: true,
									savarejo_cookie_third: true,
								})
							}
						>
							ACEITAR TODOS
						</Button>
					</Flex>
				</Stack>
			)}
			<CookiesModal isOpen={isOpen} onClose={onClose} handleSave={handleSave} />
		</>
	);
}
