import {
	AspectRatio,
	Box,
	Flex,
	HStack,
	Image,
	Text,
	VStack,
	useMediaQuery,
	usePrefersReducedMotion,
} from "@chakra-ui/react";
import { FC } from "react";

import { ItemProps } from "~/utils/Types/Home/YoutubeLive";
import { useControlLiveView } from "~/contexts/ControlLiveViewContext";
import { PopoverShare } from "~/components/Portal/PopoverShare";

interface LiveWindowProps {
	items: ItemProps[];
}

const changeColor = `
  from { background-color: #63171B; }
  to { background-color: red; }
`;

export const LiveWindow: FC<LiveWindowProps> = ({ items }) => {
	const { componentRef } = useControlLiveView();
	const [isSmallVersion] = useMediaQuery("(max-width: 340px)");

	const prefersReducedMotion = usePrefersReducedMotion();

	const animation = prefersReducedMotion
		? undefined
		: `${changeColor} infinite 0.75s alternate-reverse`;

	return (
		<VStack m="0 auto" my="6" spacing="0" rounded="xl" maxW="901px" px={2}>
			<HStack
				py="1"
				px="4"
				w="100%"
				m="0 auto"
				maxW="901px"
				gap={2}
				color="white"
				align="center"
				justify="flex-start"
				roundedTop="xl"
				bg="backgroundLiveHeader"
			>
				<Text textStyle="textLG" color="white" mb="-1">
					Canal
				</Text>
				<Image src="/logoSALive.png" h="7" alt="Logo SA Live" />
				<Flex
					w="10px"
					h="10px"
					rounded="full"
					bg="red.900"
					ml="auto"
					animation={animation}
				/>
				<Text textStyle="textLG" color="white" mb="-1">
					ESTAMOS AO VIVO
				</Text>
			</HStack>
			<AspectRatio
				ratio={16 / 9}
				w="100%"
				m="0 auto"
				maxW="901px"
				ref={componentRef}
			>
				<iframe
					style={{ borderRadius: "0px 0px 0.75rem 0.75rem" }}
					title={items[0].snippet.title}
					src={`https://www.youtube.com/embed/${items[0]?.id.videoId}?autoplay=1&mute=1`}
					allowFullScreen
				/>
			</AspectRatio>
			<HStack
				w="110%"
				spacing="6"
				ml="auto"
				sx={{
					"@media (max-width: 1126px)": {
						width: "100%",
					},
				}}
			>
				<Box
					w="100%"
					border="none"
					h="2px"
					mt="3"
					bg="linear-gradient(to right, #747474 65%, transparent 0)"
					bgSize="35px 1px"
				/>
				<PopoverShare
					linkHref=""
					type="other"
					maxWidth={isSmallVersion ? "220px" : "auto"}
				>
					<Image
						mt="-8"
						mr="-6"
						src="/Botao_compartilhar.png"
						alt="Botão compartilhar"
						cursor="pointer"
						sx={{
							"@media (max-width: 1126px)": {
								marginTop: 0,
							},
							"@media (max-width: 960px)": {
								marginRight: 0,
							},
						}}
					/>
				</PopoverShare>
			</HStack>
		</VStack>
	);
};
