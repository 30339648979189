export function getInfosEmbed(embed: string) {
	if (!embed || embed.length < 1) {
		return {
			url: '',
			code: ''
		}
	}

	const url = embed.split('src="')[1].split(/[ >]/)[0].replace('"', '')

	if (url.split('embed/')[1]?.split('?')[0]) {
		return {
			url,
			code: url.split('embed/')[1].split('?')[0]
		}
	} else {
		return {
			url: url.split('?title')[0],
			code: url.split('video/')[1].split('?')[0]
		}
	}
}
