import { AspectRatio, Box, Button, Flex, HStack, Icon, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { Fragment, useMemo, useState } from "react";
import { HiOutlineArrowTrendingUp } from "react-icons/hi2";
import { getInfosEmbed } from "~/utils/getInfosEmbed";
import { VideosHomeProps } from "~/utils/Types/Home";
import { Title } from "../Title";
import { CardVideo } from "./CardVideo";
import { FaComment } from "react-icons/fa";

interface PortalVideosProps {
	videos: VideosHomeProps[]
}

export function PortalVideos({ videos }: PortalVideosProps) {
	const [page, setPage] = useState(1)
	const isWideVersion = useBreakpointValue({
		base: false,
		"2xl": true,
	})

	const [videoSelectedSecureId, setVideoSelectedSecureId] = useState<string>()

	const videoSelected = useMemo(() => {
		if (!!videoSelectedSecureId) {
			return videos.find(video => video.secure_id === videoSelectedSecureId)
		}
		return videos[0]
	}, [videoSelectedSecureId])

	const videosPagined = useMemo(() => {
		if (isWideVersion) {
			return videos
		}
		const start = page === 1 ? 0 : page === 2 ? 4 : page === 3 ? 8 : 12
		const end = page === 1 ? 4 : page === 2 ? 8 : page === 3 ? 12 : 16
		return videos.slice(start, end)
	}, [videos, page, isWideVersion])

	return (
		<VStack
			as="section"
			backgroundImage="./bgVideos.webp"
			backgroundPosition="0 -680px"
			backgroundRepeat="no-repeat"
			backgroundColor="#161A29"
			alignItems="center"
			justifyContent="center"
			paddingBottom="14"
			paddingLeft="6"
			paddingRight="6"
			position="relative"
		>
			<VStack
				maxWidth="1326px"
				width="100%"
				alignItems="flex-start"
				gap={7}
				marginTop="28"
				flex="1"
				py={isWideVersion ? "4" : undefined}
				bottom="-5rem"
				h="100%"
			>
				<Title as="h2" text="Vídeos" color="#FFF" />
				<Flex
					flex="1"
					gap="5"
					width="100%"
					justify="flex-start"
					sx={{
						"@media (max-width: 1279px)": {
							justifyContent: "center",
						},
					}}
				>
					{isWideVersion && videos.length && !!videoSelected && !!videoSelected.embed && videoSelected.embed.length && (
						<Flex
							width="100%"
							maxWidth="901px"
							flexDirection="column"
							justify="flex-start"
							align="flex-start"
							sx={{
								"@media (max-width: 1365px)": {
									maxWidth: "830px",
								},
								"@media (max-width: 1279px)": {
									maxWidth: "901px",
								},
							}}
						>
							<AspectRatio
								ratio={16 / 9}
								w="100%"
								css={{
									'iframe': {
										width: '100%',
										height: '100%'
									}
								}}
							>
								{videoSelected.embed.length ? (
									<iframe
										title={videoSelected.title}
										src={getInfosEmbed(videoSelected.embed).url}
									/>
								) : <></>}
							</AspectRatio>
							{/* <HStack spacing="10" marginTop="8" marginLeft="14">
								<Text textStyle="textLG" color="#FFF" display="flex" gap="2" align="center" justifyContent="center">
									<Icon as={FaComment} color="yellow" fontSize="lg" />
									{videoSelected.comment_count ?? 0} Comentários
								</Text>
								<Text textStyle="textLG" color="#FFF" display="flex" gap="2">
									<Icon as={HiOutlineArrowTrendingUp} color="green" fontSize="lg" />
									{videoSelected.view_count ?? 0} Views
								</Text>
							</HStack> */}
						</Flex>
					)}
					<Box width="385px" position="relative" sx={{
						"@media (max-width: 390px)": {
							width: "100%",
						},
					}}>
						{videosPagined.length && (
							<VStack
								position={isWideVersion ? "absolute" : undefined}
								width="385px"
								sx={{
									"@media (max-width: 390px)": {
										width: "100%",
									},
								}}
								maxHeight={isWideVersion ? "700px" : undefined}
								background={isWideVersion ? "#FFF" : undefined}
								rounded="md"
								spacing="8"
								padding={isWideVersion ? "10" : "4"}
								overflowY={isWideVersion ? "scroll" : undefined}
								shadow={isWideVersion ? "lg" : undefined}
								css={{
									'::-webkit-scrollbar': {
										backgroundColor: "rgba(0, 0, 0, 0.16)",
										width: '10px',
									},
									'::-webkit-scrollbar-thumb': {
										background: '#de0045',
										borderRadius: '20px',
									}
								}}
							>
								{videosPagined.map(video => (
									<Fragment key={video.secure_id}>
										<CardVideo
											video={video}
											setVideoSelectedSecureId={setVideoSelectedSecureId}
										/>
									</Fragment>
								))}
								{!isWideVersion && (
									<HStack>
										<Button
											background={page === 1 ? "#000" : 'whiteAlpha.900'}
											color={page === 1 ? "#FFF" : undefined}
											rounded="full"
											onClick={() => setPage(1)}
										>
											1
										</Button>
										{videos.length > 4 && (
											<Button
												background={page === 2 ? "#000" : 'whiteAlpha.900'}
												color={page === 2 ? "#FFF" : undefined}
												rounded="full"
												onClick={() => setPage(2)}
											>
												2
											</Button>
										)}
										{videos.length > 8 && (
											<Button
												background={page === 3 ? "#000" : 'whiteAlpha.900'}
												color={page === 3 ? "#FFF" : undefined}
												rounded="full"
												onClick={() => setPage(3)}
											>
												3
											</Button>
										)}
										{videos.length > 12 && (
											<Button
												background={page === 3 ? "#000" : 'whiteAlpha.900'}
												color={page === 3 ? "#FFF" : undefined}
												rounded="full"
												onClick={() => setPage(3)}
											>
												4
											</Button>
										)}
									</HStack>
								)}
							</VStack>
						)}
					</Box>
				</Flex>
			</VStack>
		</VStack>
	)
}
