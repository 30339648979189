import {
	Button,
	Flex,
	HStack,
	Icon,
	ListItem,
	Stack,
	Tag,
	Text,
	UnorderedList,
	useBreakpointValue,
	VStack
} from "@chakra-ui/react";
import Image from "next/image";
import { TouchEvent, useEffect, useState } from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { RiFireFill } from "react-icons/ri";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";
import { PostHomeProps } from "~/utils/Types/Home";
import { AuthorPortal } from "../Author";
import { ButtonReadMore } from "../Buttons/ButtonReadMore";
import { PopoverShare } from "../PopoverShare";

interface CoverProps {
	news: PostHomeProps[];
}

export const CoverPortal = ({ news }: CoverProps) => {
	const isWideVersion = useBreakpointValue({
		base: false,
		xl: true,
	});

	const [bannerNumber, setBannerNumber] = useState(0);
	const [position, setPosition] = useState<{
		xDown: number | null;
		yDown: number | null;
	}>({
		xDown: null,
		yDown: null,
	});
	const [timeId, setTimeId] = useState<number | null>(null);

	const handlePrevSlide = () => {
		if (!!news) {
			const totalBanners = news.length;
			if (bannerNumber <= totalBanners - 1 && bannerNumber !== 0) {
				setBannerNumber(bannerNumber - 1);
			}
		}
	};

	const handleNextSlide = (clickOrTouch: boolean) => {
		if (!!news) {
			const totalBanners = news.length;
			if (bannerNumber < totalBanners - 1) {
				setBannerNumber(bannerNumber + 1);
			} else if (!clickOrTouch) {
				setBannerNumber(0);
			}
		}
	};

	const startTime = () => {
		if (timeId) {
			clearTime();
			const id = setTimeout(handleNextSlide, 10000);
			setTimeId(() => id);
		} else {
			const id = setTimeout(handleNextSlide, 10000);
			setTimeId(() => id);
		}
	};

	const clearTime = () => {
		if (timeId) {
			clearTimeout(timeId);
		}
	};

	useEffect(() => {
		if (!!news) {
			startTime();
		}
	}, [news, bannerNumber]);

	const getTouches = (event: TouchEvent<HTMLDivElement>): React.TouchList => {
		return event.touches;
	};

	const handleTouchStart = (event: TouchEvent<HTMLDivElement>): void => {
		const firstTouch = getTouches(event)[0];
		setPosition(() => ({
			xDown: firstTouch.clientX,
			yDown: firstTouch.clientY,
		}));
	};

	const handleTouchMove = (event: TouchEvent<HTMLDivElement>): void => {
		if (position.xDown && position.yDown) {
			const xUp = event.touches[0].clientX;
			const yUp = event.touches[0].clientY;

			const xDiff = position.xDown - xUp;
			const yDiff = position.yDown - yUp;

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				if (xDiff > 0) {
					handleNextSlide(true);
				} else {
					handlePrevSlide();
				}
			}
			setPosition(() => ({
				xDown: null,
				yDown: null,
			}));
		}
	};

	return (
		<Flex
			w="100%"
			h="41.875rem"
			maxH="41.875rem"
			position="relative"
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			sx={{
				"@media (max-width: 350px)": {
					height: "47rem",
					maxHeight: "47rem",
				},
			}}
		>
			<Image
				src={news[bannerNumber].thumbnail?.url ?? ''}
				style={{
					zIndex: '-1',
					objectFit: 'cover',
				}}
				alt={news[bannerNumber].thumbnail?.alt_text ?? news[bannerNumber].title}
				layout="fill"
				priority
				quality={80}
				sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
			/>
			<Flex
				bg="#00000035"
				w="100%"
				h="100%"
				alignItems="center"
				justify="center"
				px="5"
				pl={14}
				flexDirection="column"
				sx={{
					"@media (max-width: 768px)": {
						paddingLeft: 4
					}
				}}
			>
				<Stack w="100%" maxW="1366px">
					<Tag
						variant="solid"
						rounded="full"
						colorScheme="pink"
						fontSize="xs"
						pt="1"
						px="5"
						maxW={24}
						as="h3"
					>
						DESTAQUES
					</Tag>
					{!!news[bannerNumber].is_sponsored && (
						<Tag
							variant="solid"
							rounded="full"
							colorScheme="gray"
							fontSize="xs"
							pt="1"
							px="5"
							maxW={24}
							as="h3"
						>
							Patrocinado
						</Tag>
					)}
					<Text
						fontSize={isWideVersion ? "4.063rem" : "2.763rem"}
						fontWeight="bold"
						lineHeight="normal"
						w="60%"
						as='h4'
						color="white"
					>
						{news[bannerNumber].title}
					</Text>
					<AuthorPortal
						author={
							news[bannerNumber].is_produced
								? news[bannerNumber].produced
								: news[bannerNumber].author?.name
						}
						avatar={
							news[bannerNumber].is_produced
								? ""
								: news[bannerNumber].author?.avatar?.url
						}
					// views={news[bannerNumber].view_count ?? 0}
					/>
					<Flex pt={10} gap="4" align="center" flexWrap="wrap">
						<ButtonReadMore
							text="LEIA MAIS"
							linkHref={FormatPortalLinks.formatPostLink({ post: news[bannerNumber] })}
						/>
						{/* falta icone */}
						{!!news[bannerNumber].views && news[bannerNumber].views! >= 500 && (
							<Button
								size="sm"
								fontSize="md"
								rounded="full"
								py={6}
								px={4}
								cursor="default"
								background="#7448DC"
								_hover={{
									background: "#7448DC",
								}}
							>
								<Icon
									as={BsFillLightningChargeFill}
									fontSize={"lg"}
									color={"#FFF"}
								/>
							</Button>
						)}
						{/* falta icone */}
						{!!news[bannerNumber].shares &&
							news[bannerNumber].shares! >= 100 && (
								<Button
									size="sm"
									fontSize="md"
									rounded="full"
									py={6}
									px={4}
									cursor="default"
									background="#E81A17"
									_hover={{
										background: "#E81A17",
									}}
								>
									<Icon as={RiFireFill} fontSize={"lg"} color={"#FFF"} />
								</Button>
							)}
						<PopoverShare
							linkHref={FormatPortalLinks.formatPostLink({ post: news[bannerNumber] })}
							postId={news[bannerNumber].secure_id}
						/>
					</Flex>
				</Stack>
				<VStack>
					<UnorderedList
						listStyleType="none"
						display="flex"
						zIndex="3"
						bottom="14"
						position="absolute"
						sx={{
							"@media (max-width: 768px)": {
								bottom: 2,
							},
						}}
					>
						<HStack spacing="2">
							{news.length > 1 && news.map((newItem, index) => (
								<ListItem
									key={newItem.secure_id}
									w="3.5"
									h="3.5"
									onClick={() => {
										setBannerNumber(index);
									}}
									cursor="pointer"
									bg={index === bannerNumber ? "backgroundHeader" : "#FFF"}
									rounded="100%"
								/>
							))}
						</HStack>
					</UnorderedList>
				</VStack>
			</Flex>
		</Flex>
	);
};
