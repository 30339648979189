import {
	Button,
	Flex,
	Text,
	Icon,
	useBreakpointValue,
} from "@chakra-ui/react"
import { useState } from "react"

import { IoIosShareAlt } from "react-icons/io"

import { useForm } from "react-hook-form"

import { InputLandingPage } from "~/components/global/Form/InputLandingPage"
import { ModalNewsLetterPortal } from "../ModalNewsLetterPortal"

type FormData = {
	email: string
}

export function Updates() {
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	})


	const { register, watch, reset } = useForm<FormData>()

	const email = watch("email")

	const [newsletterModal, setNewsletterModal] = useState(false)

	const handleCloeseNewsletterModal = () => {
		reset()
		setNewsletterModal(false)
	}
	const handleOpenNewsletterModal = () => setNewsletterModal(true)

	return (
		<>
			<Flex
				as="section"
				align="center"
				justify="center"
				background="#FFF"
				flexDirection="column"
				px="6"
				pb="14"
			>
				<Text as='h3' textStyle="headerXL" fontSize={isWideVersion ? "xxx-large" : "xx-large"}>
					Não perca nossas atualizações!
				</Text>
				<Text textStyle="textLG" color="blackAlpha.700" fontSize="xl">
					Inscreva-se em nossa lista de e-mail para receber atualizações
				</Text>
				<Flex
					as="form"
					width="100%"
					bg="#F5F5F5"
					marginTop="6"
					rounded="full"
					maxWidth="500px"
					justify="space-between"
				>
					<InputLandingPage
						border="transparent"
						height={isWideVersion ? "65px" : "55px"}
						maxWidth="400px"
						width="100%"
						boxShadow="none"
						_focusVisible={{
							shadow: 'none'
						}}
						placeholder="Digite seu e-mail aqui"
						{...register('email')}
					/>
					<Button
						as="button"
						type="button"
						bg="#DD0146"
						color="#FFF"
						rounded="full"
						height={isWideVersion ? "65px" : "55px"}
						padding={isWideVersion ? "0 3rem" : "0 2.5rem"}
						leftIcon={
							<Icon as={IoIosShareAlt} color="#FFF" fontSize="2xl" />
						}
						isDisabled={!email}
						onClick={handleOpenNewsletterModal}
						_hover={{
							opacity: 0.4
						}}
					>
						<Text whiteSpace="nowrap" mb="-0.35rem">
							SE INSCREVER
						</Text>
					</Button>
				</Flex>
			</Flex>
			{newsletterModal && (
				<ModalNewsLetterPortal
					email={email}
					isOpen={newsletterModal}
					onClose={handleCloeseNewsletterModal}
				/>
			)}
		</>
	)
}
