import {
	Flex,
	Grid,
	useBreakpointValue,
	useMediaQuery,
	VStack
} from "@chakra-ui/react";
import { Fragment, useLayoutEffect } from "react";
import { AdSlot } from "~/components/Ads/AdSlot";
import { defineSlot } from '~/lib/dfp/index';
import { PostHomeProps } from "~/utils/Types/Home";
import { Title } from "../Title";
import { CardTopics } from "./CardTopics";

type TopicsOfTheDayProps = {
	topicsDay: PostHomeProps[];
};

export function TopicsOfTheDay({ topicsDay }: TopicsOfTheDayProps) {
	const [isLargerThan1365] = useMediaQuery("(min-width: 1365px)");
	const isLargeVersion = useBreakpointValue({
		base: false,
		lg: true,
	});

	useLayoutEffect(() => {
		defineSlot('/100074611/Bloco-1160x170', 'div-gpt-ad-1711110502742-0', [[1160, 170], [750, 100], [468, 60], [320, 50]], ['Home Notícias de Hoje 1160x170'])
		defineSlot('/100074611/Bloco-390x300', 'div-gpt-ad-1711110602742-0', [[390, 300], [312, 240]], ['Home Notícias de Hoje 390x300 1'])
		defineSlot('/100074611/Bloco-390x300', 'div-gpt-ad-1711110702742-0', [[390, 300], [312, 240]], ['Home Notícias de Hoje 390x300 2'])
	}, [])

	return (
		<VStack
			as="section"
			background="#FFF7F8"
			alignItems="center"
			justifyContent="center"
			spacing="0"
			paddingX={6}
			paddingY={isLargeVersion ? "20" : "14"}
		>
			<VStack
				maxWidth="1326px"
				width="100%"
				alignItems="center"
				gap="1.25rem"
				spacing={8}
			>
				<Title text="Notícias de Hoje" as='h3' />
				<Flex
					width="100%"
					flexDirection="row"
					sx={{
						"@media (max-width: 910px)": {
							flexDirection: "column",
						},
					}}
				>
					<Grid
						w="100%"
						templateRows="repeat(3, 160px)"
						templateColumns={
							isLargerThan1365
								? "repeat(4, 1fr)"
								: isLargeVersion
									? "repeat(2, 1fr)"
									: "repeat(1, 1fr)"
						}
						gap="1.25rem"
						sx={{
							"@media (max-width: 910px)": {
								gridTemplateRows: "repeat(6, 160px)",
								gridTemplateColumns: "repeat(1, 1fr)",
								paddingTop: 0,
								gap: "4.25rem"
							},
						}}
					>
						{topicsDay?.map((topicsDayItem, index) => (
							<Fragment key={topicsDayItem.secure_id}>
								{(index === 0 && isLargeVersion) ? (
									<CardTopics isFirstCard topicOfTheDay={topicsDayItem} />
								) : (
									<CardTopics topicOfTheDay={topicsDayItem} />
								)}
							</Fragment>
						))}
					</Grid>
					<Flex
						flexDir="column"
						width="100%"
						align="center"
						justify={isLargerThan1365 ? undefined : "flex-start"}
						gap="8"
						flex="1"
					>
						<AdSlot
							id={'div-gpt-ad-1711110602742-0'}
							customStyle={{
								marginLeft: !!isLargeVersion ? "1.25rem" : undefined
							}}
						/>
						<AdSlot
							id={'div-gpt-ad-1711110702742-0'}
							customStyle={{
								marginLeft: !!isLargeVersion ? "1.25rem" : undefined
							}}
						/>
					</Flex>
				</Flex>
			</VStack>
			<AdSlot
				id={'div-gpt-ad-1711110502742-0'}
				customStyle={{
					marginTop: '4rem',
					height: '100%',
					maxHeight: '170px',
				}}
			/>
		</VStack>
	);
}
