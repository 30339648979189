import { Box, Flex, HStack, Icon, Image, Tag, TagLabel, Text, VStack } from "@chakra-ui/react"
import Link from "next/link"
import "keen-slider/keen-slider.min.css"
import { useKeenSlider } from "keen-slider/react"
import { useMemo, useState } from "react"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"
import { GetColorTags } from "~/utils/GetColorTags"
import { PostHomeProps } from "~/utils/Types/Home"
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks"

type ArrowProps = {
	disabled: boolean;
	left?: boolean;
	onClick: (e: any) => void;
}

type SlideEmphasisPostProps = {
	featuredPost: PostHomeProps[]
}

function Arrow({ disabled, left, onClick }: ArrowProps) {
	return (
		<Box
			width="30px"
			height="30px"
			rounded="full"
			justifyContent="center"
			alignItems="center"
			display="flex"
			cursor="pointer"
			onClick={onClick}
			left={left ? '5' : undefined}
			right={left ? undefined : '5'}
		>
			{left && (
				<Icon as={BsArrowLeft} fontSize="3xl" color="whiteAlpha.900" />
			)}
			{!left && (
				<Icon as={BsArrowRight} fontSize="3xl" color="whiteAlpha.900" />
			)}
		</Box>
	)
}

export function SlideEmphasisPost({ featuredPost }: SlideEmphasisPostProps) {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
		initial: 0,
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel)
		},
		created() {
			setLoaded(true)
		},
	})

	const postActive = useMemo(() => {
		return featuredPost[currentSlide]
	}, [currentSlide])

	const beforePostActive = useMemo(() => {
		return featuredPost[currentSlide - 1]
	}, [currentSlide])

	const afterPostActive = useMemo(() => {
		return featuredPost[currentSlide + 1]
	}, [currentSlide])

	return (
		<Flex position="relative" >
			<Box
				ref={sliderRef}
				className="keen-slider"
				rounded="lg"
			>
				{featuredPost.map(post => (
					<Link key={post.secure_id} href={FormatPortalLinks.formatPostLink({ post: postActive })}>
						<Image
							height="550px"
							width="360px"
							className="keen-slider__slide"
							objectFit="cover"
							src={post.thumbnail?.url}
							alt={post.thumbnail?.alt_text ?? post.title}
						/>
					</Link>
				))}
			</Box>
			<VStack
				as={Link}
				href={FormatPortalLinks.formatPostLink({ post: postActive })}
				position="absolute"
				bottom="50px"
				padding="30px"
				align="flex-start"
				w="100%"
			>
				{!!postActive.tags && !!postActive.tags.length && (
					<HStack>
						{postActive.tags.split(',').slice(0, 3).map((tag, index) => (
							<Tag
								key={tag}
								size="lg"
								borderRadius='full'
								variant='solid'
								background={GetColorTags(index)}
								padding="0 1rem"
							>
								<TagLabel>{tag}</TagLabel>
							</Tag>
						))}
					</HStack>
				)}
				<Box
					p="2"
					bg="#FFF"
					rounded="md"
				>
					<Text w="100%" textStyle="headerLG" as="h4" noOfLines={3}>
						{postActive.title}
					</Text>
				</Box>
			</VStack>
			{(featuredPost.length > 1) && loaded && instanceRef.current && (
				<Flex
					position="absolute"
					bottom="15px"
					justify="center"
					align="center"
					width="100%"
					gap="2"
				>
					<Arrow
						left
						onClick={(e: any) =>
							e.stopPropagation() || instanceRef.current?.prev()
						}
						disabled={currentSlide === 0}
					/>
					<Box rounded="md" backgroundImage={beforePostActive?.thumbnail?.url} width="50px" height="50px" marginLeft="3" backgroundSize="cover" />
					<Box rounded="md" backgroundImage={afterPostActive?.thumbnail?.url} width="50px" height="50px" marginRight="3" backgroundSize="cover" />
					<Arrow
						onClick={(e: any) =>
							e.stopPropagation() || instanceRef.current?.next()
						}
						disabled={
							currentSlide ===
							instanceRef.current.track.details.slides.length - 1
						}
					/>
				</Flex>
			)}
		</Flex>
	)
}
