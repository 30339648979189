import {
	FormControl,
	FormErrorMessage,
	FormLabel, Input as ChakraInput,
	InputProps as ChakraInputProps,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

import { forwardRef, ForwardRefRenderFunction } from 'react'

interface InputProps extends ChakraInputProps {
	name: string
	label?: string
	placeholder?: string
	error?: FieldError
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, label, error = null, ...rest }, ref) => {
	return (
		<FormControl isInvalid={!!error}>
			{!!label &&
				<FormLabel
					w="100%"
					htmlFor={name}
					display="flex"
					alignItems="center"
					fontWeight="bold"
				>
					{label}
				</FormLabel>
			}
			<ChakraInput
				fontSize={{ sm: 'md', md: 'lg' }}
				id={name}
				name={name}
				size="lg"
				variant="outline"
				ref={ref}
				{...rest}
			/>
			{error &&
				<FormErrorMessage fontSize="xs">{error.message}</FormErrorMessage>
			}
		</FormControl>
	)
}

export const InputLandingPage = forwardRef(InputBase)
