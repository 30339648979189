import { Box, Button, ButtonProps, Flex, Icon, Text } from "@chakra-ui/react"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC } from "react"
import { IconType } from "react-icons"
import { MdArrowForward } from "react-icons/md"

interface ButtonReadMoreProps extends ButtonProps {
	linkHref: string
	icon?: IconType
	sizeIcon?: string
	text: string
	color?: string
	bg?: string
}

export const ButtonReadMore: FC<ButtonReadMoreProps> = ({ icon, linkHref, sizeIcon, text, color, bg, fontWeight, ...rest }) => {
	const router = useRouter()
	const iconButton = icon ? icon : MdArrowForward

	return (
		<Box display="inline-block">
			<Button
				as={Link}
				href={linkHref}
				passHref
				size="sm"
				fontSize="md"
				variant="solid"
				color={color ? color : "white"}
				rounded="full"
				py={6}
				px={4}
				bg={bg ? bg : "red"}
				{...rest}
			>
				<Text mb="-0.35rem" fontWeight={fontWeight ?? "bold"}>{text}</Text>
				<Flex rounded="full" bg="#fff" w={8} h={8} align="center" justifyContent="center" ml={2}>
					<Icon as={iconButton} fontSize={!!sizeIcon ? sizeIcon : "lg"} color="black" />
				</Flex>
			</Button>
		</Box>
	)
}
