import {
	Box,
	Flex,
	Grid,
	Stack,
	UnorderedList,
	useBreakpointValue,
	useMediaQuery,
	VStack
} from "@chakra-ui/react";
import { Fragment, useLayoutEffect } from "react";
import { AdSlot } from "~/components/Ads/AdSlot";
import { defineSlot } from "~/lib/dfp";
import { HotCategories, PostHomeProps } from "~/utils/Types/Home";
import { Title } from "../Title";
import { CardBestOfWeek } from "./CardBestOfTheWeek";
import { CardHotCategoryItem } from "./CardCategoryItem";

interface BestOfTheWeekProps {
	bestWeek: PostHomeProps[];
	hotCategories: HotCategories[];
}

export function BestOfTheWeek({ bestWeek, hotCategories }: BestOfTheWeekProps) {
	const isLargeVersion = useBreakpointValue({
		base: false,
		lg: true,
	});

	useLayoutEffect(() => {
		defineSlot('/100074611/Bloco-1160x170', 'div-gpt-ad-1711110102742-0', [[1160, 170], [750, 100], [468, 60], [320, 50]], ['Home Melhores da Semana 1160x170'])
	}, [])

	return (
		<Stack
			as="section"
			background="#FFFFFF"
			alignItems="center"
			justifyContent="center"
			paddingBottom={{ sm: 10, lg: 14 }}
			paddingX="6"
			spacing={0}
			paddingTop={{ sm: 24, lg: 44 }}
		>
			<Stack
				maxWidth="1326px"
				width="100%"
				alignItems="center"
				gap="1.25rem"
			>
				<Grid
					gap="4"
					width="100%"
					gridTemplateColumns={{ sm: '1fr', '3xl': '1fr 390px' }}
				>
					<Stack
						align="flex-start"
						w="100%"
						spacing="8"
					>
						<Title text="Em Alta na Semana" as='h3' />
						<Grid
							gap="1.25rem"
							gridTemplateColumns={{ sm: '1fr', '3xl': '1fr 1fr' }}
							w="100%"
						>
							{bestWeek[0] && (
								<CardBestOfWeek isFirstCard bestWeek={bestWeek[0]} />
							)}
							<Stack spacing={4}>
								{bestWeek.map((bestWeekItem, index) => (
									<Fragment key={bestWeekItem.secure_id}>
										{index === 0 ? (
											<></>
										) : (
											<CardBestOfWeek bestWeek={bestWeekItem} />
										)}
									</Fragment>
								))}
							</Stack>
						</Grid>
					</Stack>
					{isLargeVersion && (
						<Flex justify="center">
							<Box width="100%" maxW="390px">
								<Title
									text="Acontece no Mercado"
									textTransform="initial"
									fontSize="1.5rem"
									heightImage="20px"
									marginBottom="4"
									as='h3'
								/>
								<UnorderedList
									rounded="lg"
									borderColor="blackAlpha.200"
									borderWidth="2px"
									spacing="3"
									borderStyle="solid"
									margin="0"
									listStyleType="none"
									padding="8"
								>
									{hotCategories.map((item) => (
										<CardHotCategoryItem
											key={item.secure_id}
											hotCategoryItem={item}
										/>
									))}
								</UnorderedList>
							</Box>
						</Flex>
					)}
				</Grid>
			</Stack>
			<AdSlot
				id={'div-gpt-ad-1711110102742-0'}
				customStyle={{
					marginTop: '3.5rem',
				}}
			/>
		</Stack>
	);
}
